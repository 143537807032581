import React, { Component } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import LoginButton from './SignIn';

export default class NavigationBar extends Component {
  render() {
    const navLinks = [];
    if (this.props.permissions !== null) {
      const rmPermissions = this.props.permissions.resourceManagerPermissions;

      if (rmPermissions.canSeeContactCenterPage) {
        navLinks.push(<Nav.Link
          key="callcenter"
          href="#/call-center">
          Business Controls
        </Nav.Link>);
      }

      if (rmPermissions.canSeeRealTimePage) {
        navLinks.push(<Nav.Link
          key="realTime"
          href="#/real-time">
          Real-Time
        </Nav.Link>);
      }

      if (rmPermissions.canSeeAgentPage) {
        navLinks.push(<Nav.Link
          key="agents"
          href="#/agents">
          Users
        </Nav.Link>);
      }

      if (rmPermissions.canSeeGlobalAdminPage) {
        navLinks.push(<Nav.Link
          key="admin"
          href="#/admin">
          Admin
        </Nav.Link>);
      }
    }

    return (
      <Navbar className="nav" bg="primary" variant="dark" expand="lg">
        <Container className="nav-container">
          <Navbar.Brand href="#">
            <img
              src="/images/sbux-white.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="Starbucks logo"
            />
            <div className="pl-2 d-inline-block">
              Connect Admin
            </div>
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="toggleIcon"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="nav-items">
              <Nav defaultActiveKey={this.props.location}>
                {navLinks}
              </Nav>
              <div className="user-section">
                <LoginButton
                  auth={this.props.auth}
                  isloggedIn={this.props.isloggedIn}
                  userName={this.props.userName}
                  signInloading={this.props.signInloading} />
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}
