import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import Logger from '../modules/Logger.js';
import { Modal, Form, Button } from 'react-bootstrap';
import RecurringMessages from '../modules/RecurringMessages';
import LineOfBusiness from './LineOfBusiness';

import Utils from '../modules/Utils';
import Enums from '../modules/Enums';
import Constants from '../modules/Constants';
import moment from 'moment';

import DateTime from 'react-datetime';

export default class EditAddRecurringMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveInProgress: false,
      ...this.formatRecurringMessageState(this.props.recurringMessages),
    };
  }

  Schedule = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };

  RecurringMessageKeyNames = {
    START: 'startDateTime',
    END: 'endDateTime',
    ENGLISH: 'englishResponse',
    FRENCH: 'frenchResponse',
    START_DATE_TIME: 'startDateTime',
    END_DATE_TIME: 'endDateTime',
  };

  formatRecurringMessageState = recurringMessage => {
    if (!recurringMessage) {
      return {
        formisValid: true,
        descriptionIsValid: true,
        dateAndTimeIsValid: true,
        startDateTime: Date.now(),
        endDateTime: Date.now(),
        startTime: '00:00',
        responseType: 'AUDIO',
        responseIsValid: true,
        description: '',
        englishResponse: '',
        frenchResponse: '',
        updateSucessful: true,
        timeValid: true,
        startTimeValid: true,
        startTimeAndEndTimeValid: true,
        duration: null,
        recurringMessageId: null,
        schedule: [false, false, false, false, false, false, false],
        edit: false,
        scheduleCheckedOff: true,
      };
    } else {
      return {
        formisValid: true,
        descriptionIsValid: true,
        dateAndTimeIsValid: true,
        startDateTime: recurringMessage.startDate,
        endDateTime: recurringMessage.endDate,
        startTime: recurringMessage.startTime,
        endTime: recurringMessage.endTime,
        responseType: recurringMessage.response_en.type,
        responseIsValid: true,
        description: recurringMessage.description,
        englishResponse: recurringMessage.response_en.value,
        frenchResponse: recurringMessage.response_fr.value,
        updateSucessful: true,
        timeValid: true,
        startTimeValid: true,
        startTimeAndEndTimeValid: true,
        duration: recurringMessage.duration,
        recurringMessageId: recurringMessage.recurringMessageId,
        schedule: recurringMessage.schedule,
        edit: true,
        scheduleCheckedOff: true,
      };
    }
  };

  handleDescriptionChange = event => {
    event.preventDefault();
    event.stopPropagation();

    const descriptionIsValid = Utils.isDescriptionValid(event.target.value);

    this.setState({
      descriptionIsValid: descriptionIsValid,
      description: event.target.value,
    });
  };

  handleEndTimeBlurEvent = event => {
    if (Utils.shouldAutoSwitchDate(this.state.startDateTime, event)) {
      this.setState({
        endDateTime: Utils.adjustDate(event, 1, 'day'),
        dateAndTimeIsValid: true,
        duration: Utils.getDurationForTimesOnly(this.state.startDateTime, Utils.adjustDate(event, 1, 'day')).totalInMins,
        startTimeAndEndTimeValid: Utils.isStartAndEndTimeValid(this.state.startDateTime, Utils.adjustDate(event, 1, 'day'))
      });
    }
  };

  handleDateAndTime = (isStart, event, date) => {
    const dateAndTime = Date.parse(date);

    const currentTimeSelectionObj = {
      currentTime: Date.now(),
    };

    const currenttimeSelection = isStart
      ? {
        ...currentTimeSelectionObj,
        selectedStartTime: dateAndTime,
        selectedEndTime: this.state.endDateTime,
      }
      : {
        ...currentTimeSelectionObj,
        selectedStartTime: this.state.startDateTime,
        selectedEndTime: dateAndTime,
      };

    const startOrEndKeyName = isStart
      ? this.RecurringMessageKeyNames.START_DATE_TIME
      : this.RecurringMessageKeyNames.END_DATE_TIME;

    const startDate = moment.tz(
      currenttimeSelection.selectedStartTime,
      Constants.TimeZone.PACIFIC_TIME
    );
    const endDate = moment.tz(
      currenttimeSelection.selectedEndTime,
      Constants.TimeZone.PACIFIC_TIME
    );

    const endHours =
      String(endDate.hour()).length === 1
        ? `0${endDate.hour()}`
        : endDate.hour();
    const startHours =
      String(startDate.hours()).length === 1
        ? `0${startDate.hour()}`
        : startDate.hour();
    const endMinutes =
      String(endDate.minute()).length === 1
        ? `0${endDate.minute()}`
        : endDate.minute();

    const startMinutes =
      String(startDate.minute()).length === 1
        ? `0${startDate.minute()}`
        : startDate.minute();

    const isStartDateValid = Utils.isStartTimeValid(currenttimeSelection);

    const isDateValid = Utils.isDateValid(currenttimeSelection);

    const getStartTime = `${startHours}:${startMinutes}`;
    const getEndTime = `${endHours}:${endMinutes}`;

    this.setState({
      [startOrEndKeyName]: dateAndTime,
      startTimeValid: isStartDateValid,
      dateAndTimeIsValid: isDateValid,
      startTimeAndEndTimeValid: Utils.isStartAndEndTimeValid(startDate, endDate),
      duration: Utils.getDurationForTimesOnly(startDate, endDate).totalInMins,
      startTime: getStartTime,
      endTime: getEndTime
    });
  };

  handleDaySelection = index => {
    const newSchedule = [...this.state.schedule];
    newSchedule[index] = !newSchedule[index];
    this.setState({
      schedule: newSchedule,
    });
  };

  handleCategoryChange = event => {
    this.setState({
      category: event.target.value,
    });
  };

  handleResponseTypeChange = event => {
    this.setState({
      responseType: event.target.value,
    });
  };

  handleResponseMessage = (language, event) => {
    const message = event.target.value;

    const otherLanguage =
      language === Enums.Language.ENGLISH
        ? Enums.Language.FRENCH
        : Enums.Language.ENGLISH;

    const otherLanguageValue = this.state[
      this.RecurringMessageKeyNames[otherLanguage]
    ];

    const responseIsValid = Utils.isResponseValid(message, otherLanguageValue);

    const reponseLanguage = this.RecurringMessageKeyNames[language];

    this.setState({
      responseIsValid,
      [reponseLanguage]: message,
    });
  };

  handleValidation = (elements, state) => {
    const currentTimeSelectionObj = {
      selectedStartTime: this.state.startDateTime,
      selectedEndTime: this.state.endDateTime,
      currentTime: Date.now(),
    };

    const isStartDateValid = Utils.isStartTimeValid(currentTimeSelectionObj);
    const isStartDateAndEndTimeTheSameValid = Utils.isStartAndEndTimeValid(this.state.startDateTime, this.state.endDateTime);
    const isDateValid = Utils.isDateValid(currentTimeSelectionObj);

    const isDescriptionValid = Utils.isDescriptionValid(this.state.description);

    const isResponseValid = Utils.isResponseValid(
      this.state.englishResponse,
      this.state.frenchResponse
    );

    const scheduleCheck = Utils.isScheduleCheckedOff(this.state.schedule);

    const isFormValid = Utils.isRecurringMeetingFormValid(
      isDescriptionValid,
      isDateValid,
      isResponseValid,
      isStartDateValid,
      isStartDateAndEndTimeTheSameValid,
      scheduleCheck
    );

    return {
      descriptionIsValid: isDescriptionValid,
      dateAndTimeIsValid: isDateValid,
      responseIsValid: isResponseValid,
      formisValid: isFormValid,
      startTimeValid: isStartDateValid,
      startTimeAndEndTimeValid: isStartDateAndEndTimeTheSameValid,
      scheduleCheckedOff: scheduleCheck,
    };
  };

  updateMessage = async newMessage => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      const isUpdateSuccess = await RecurringMessages.postRecurringMessage(
        Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness],
        newMessage,
        idJwtToken
      );

      return isUpdateSuccess;
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  };

  handleSubmit = async event => {
    if (this.state.saveInProgress) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    this.setState({ saveInProgress: true });

    const checkValidation = this.handleValidation(event.target, this.state);

    if (checkValidation.formisValid) {
      const copyState = this.state;
      const newMessage = Utils.formatRecurringMessageToAPI(
        copyState,
        Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness]
      );

      const isUpdateSuccess = await this.updateMessage(newMessage);

      if (isUpdateSuccess) {
        this.props.updateLocalMessage(newMessage);
        this.props.closeModal();
      } else {
        this.setState({
          updateSucessful: false,
          saveInProgress: false,
        });
      }
    } else {
      this.setState({
        descriptionIsValid: checkValidation.descriptionIsValid,
        dateAndTimeIsValid: checkValidation.dateAndTimeIsValid,
        startTimeValid: checkValidation.startTimeValid,
        startTimeAndEndTimeValid: checkValidation.startTimeAndEndTimeValid,
        responseIsValid: checkValidation.responseIsValid,
        formisValid: checkValidation.formisValid,
        scheduleCheckedOff: checkValidation.scheduleCheckedOff,
        updateSucessful: true,
        saveInProgress: false,
      });
    }
  };

  handleClose = () => {
    this.props.closeModal();
  };

  getTitle = () => {
    const currentOrExpiredOrUpcoming = Utils.messageStatus(
      this.props.recurringMessages
    );
    switch (currentOrExpiredOrUpcoming) {
      case 'UPCOMING':
      case 'CURRENT':
        return 'Update Recurring Message';

      case 'EXPIRED':
      default:
        return 'Create Recurring Message';
    }
  };

  getSaveButton = () => {
    return this.state.saveInProgress ? (
      <Button disabled type="submit" className="submitCloseButtons">
        Saving...
      </Button>
    ) : (
        <Button type="submit" className="submitCloseButtons">
          Save
        </Button>
      );
  };

  getCloseButton = () => {
    return this.state.saveInProgress ? (
      <Button
        disabled
        variant="outline-secondary"
        onClick={this.handleClose}
        className="submitCloseButtons"
      >
        Close
      </Button>
    ) : (
        <Button
          variant="outline-secondary"
          onClick={this.handleClose}
          className="submitCloseButtons"
        >
          Close
        </Button>
      );
  };

  render() {
    const modalTitle = this.getTitle();
    const saveButton = this.getSaveButton();
    const closeButton = this.getCloseButton();
    return (
      <div>
        <Modal show={this.props.modalStatus} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate onSubmit={this.handleSubmit} className="conatiner">
              <div className="formSection noBorder">
                <LineOfBusiness
                  linesOfBusiness={[this.props.currentLineOfBusiness]}
                  currentLineOfBusiness={this.props.currentLineOfBusiness}
                  readOnly={true}
                  className="col-12"
                />

                <Form.Group controlId="Description">
                  <Form.Label className="formLabel">Description</Form.Label>

                  <Form.Control
                    required
                    type="text"
                    defaultValue={this.state.description}
                    onChange={this.handleDescriptionChange}
                    name="Description"
                  />
                </Form.Group>
                <div
                  className={
                    this.state.descriptionIsValid
                      ? 'removeContent'
                      : 'displayContent validateForm'
                  }
                >
                  Please make sure the description length is between 1 to 50
                  characters.
                </div>
              </div>
              <h5 className="formSectionHeader">Time</h5>

              <div className="formSection container recurringDate">
                <div className="formGroup">
                  <Form.Label className="dateLabel">Start Date</Form.Label>

                  <Form.Label className="dateLabel">End Date</Form.Label>

                  <DateTime
                    value={new Date(Number(this.state.startDateTime))}
                    timeFormat={false}
                    name="startDateTime"
                    className="dateTimePicker"
                    onChange={this.handleDateAndTime.bind(
                      this,
                      true,
                      this.name
                    )}
                  />

                  <DateTime
                    value={new Date(Number(this.state.endDateTime))}
                    timeFormat={false}
                    name="endDateTime"
                    className="dateTimePicker"
                    onChange={this.handleDateAndTime.bind(
                      this,
                      false,
                      this.name
                    )}
                  />

                  <Form.Label className="dateLabel">Start Time</Form.Label>

                  <Form.Label className="dateLabel">End Time</Form.Label>

                  <DateTime
                    value={new Date(Number(this.state.startDateTime))}
                    dateFormat={false}
                    timeFormat={Constants.DateFormat.TIME}
                    name="startDateTime"
                    className="dateTimePicker"
                    onChange={this.handleDateAndTime.bind(
                      this,
                      true,
                      this.name
                    )}
                  />

                  <DateTime
                    value={new Date(Number(this.state.endDateTime))}
                    dateFormat={false}
                    timeFormat={Constants.DateFormat.TIME}
                    name="endDateTime"
                    className="dateTimePicker"
                    onChange={this.handleDateAndTime.bind(
                      this,
                      false,
                      this.name
                    )}
                    onBlur={this.handleEndTimeBlurEvent.bind(this)}
                  />

                  <div className="durationContainer">
                    <Form.Label className="dateLabel">Duration</Form.Label>

                    <div className="durationTime">{Utils.getDurationForTimesOnly(this.state.startDateTime, this.state.endDateTime).formattedDuration}</div>
                  </div>
                </div>
                <div
                  className={
                    this.state.dateAndTimeIsValid
                      ? 'removeContent'
                      : 'displayContent validateForm'
                  }
                >
                  Please make sure the end date is after the start date.
                </div>

                <div
                  className={
                    this.state.startTimeValid
                      ? 'removeContent'
                      : 'displayContent validateForm'
                  }
                >
                  Please make sure the start date is after today.
                </div>

                <div
                  className={
                    this.state.startTimeAndEndTimeValid
                      ? 'removeContent'
                      : 'displayContent validateForm'
                  }
                >
                  The start time cannot be the same as the end time.
                </div>
              </div>

              <Form.Label className="formLabel">Schedule</Form.Label>

              <div className="schedule">
                {this.state.schedule.map((value, index) => {
                  return this.state.edit ? (
                    <div className="scheduleDays" key={index}>
                      <Form.Check
                        disabled
                        onChange={this.handleDaySelection.bind(this, index)}
                        checked={value}
                        label={this.Schedule[index]}
                      />
                    </div>
                  ) : (
                      <div className="scheduleDays" key={index}>
                        <Form.Check
                          onChange={this.handleDaySelection.bind(this, index)}
                          checked={value}
                          label={this.Schedule[index]}
                        />
                      </div>
                    );
                })}
                <div
                  className={
                    this.state.scheduleCheckedOff
                      ? 'removeContent'
                      : 'displayContent validateForm'
                  }
                >
                  Please select at least a day.
                </div>
              </div>

              <h5 className="formSectionHeader">Response</h5>
              <div className="formSection container mb-2">
                <div className="formGroup">
                  <Form.Label className="formLabel">Type</Form.Label>

                  <div className="selectFormSizeControl">
                    <Form.Control
                      as="select"
                      value={this.state.responseType}
                      name="responseType"
                      onChange={this.handleResponseTypeChange}
                    >
                      <option>AUDIO</option>
                      <option>TTS</option>
                      <option>SSML</option>
                    </Form.Control>
                  </div>
                </div>

                <Form.Group
                  controlId="englishResponse"
                  className="emergencyMessageResponseInputText formGroup"
                >
                  <Form.Label className="formLabel">English (en_US)</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    defaultValue={this.state.englishResponse}
                    onChange={this.handleResponseMessage.bind(
                      this,
                      Enums.Language.ENGLISH
                    )}
                    name="englishResponse"
                  />
                </Form.Group>

                <Form.Group
                  controlId="frenchResponse"
                  className="emergencyMessageResponseInputText formGroup"
                >
                  <Form.Label className="formLabel">
                    Canadian French (fr_CA)
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    defaultValue={this.state.frenchResponse}
                    onChange={this.handleResponseMessage.bind(
                      this,
                      Enums.Language.FRENCH
                    )}
                    name="frenchResponse"
                  />
                </Form.Group>

                <div
                  className={
                    this.state.responseIsValid
                      ? 'removeContent'
                      : 'displayContent validateForm'
                  }
                >
                  Please make sure the length of responses are between 1 to 1000
                  characters.
                </div>
              </div>
              {saveButton}
              {closeButton}

              <div
                className={
                  this.state.updateSucessful
                    ? 'removeContent'
                    : 'displayContent validateForm'
                }
              >
                Updating Planned message was not successful. Please try again.
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
