import Logger from './Logger.js';
import {
  Auth
} from 'aws-amplify';

/**
 * Handles all JWT related tasks
 * @author Adam Rau
 */
export default class JwtProvider {

  /**
   * Get the JWT token
   * @returns the token if it can be obtained otherwise returns undefined
   */
  static getToken = async () => {
    let token;

    try {
      const session = await Auth.currentSession();
      token = session.getIdToken().getJwtToken();
    } catch (err) {
      Logger.error(`Could not get the token due to err ${err}`);
    }

    return token;
  };
}
