import React, { Component } from 'react';
import {Form} from 'react-bootstrap';

export default class LineOfBusiness extends Component {

  buildLineOfBusinessElement = (
    // TODO: linesOfBusiness
    linesOfBusiness,
    currentLineOfBusiness,
    readOnly,
  ) => {
    if (linesOfBusiness.length > 1) {
      const arrayOfLinesOfBusinesses = linesOfBusiness.map((x, index) => {
        return <option key={index}>
          {x}
        </option>;
      });

      return readOnly
        ? <Form>
          <Form.Group
            controlId="exampleForm.ControlSelect1"
            className="lineOfBusinessFormTitle">

            <div className="lineOfBusinessTitle">
              Line Of Business
            </div>

            <Form.Control
              as="select"
              value={currentLineOfBusiness}
              onChange={this.props.changeLineOfBusiness}
              disabled>
              {arrayOfLinesOfBusinesses}
            </Form.Control>
          </Form.Group>
        </Form>
        : <Form>
          <Form.Group
            controlId="exampleForm.ControlSelect1"
            className="lineOfBusinessFormTitle">

            <div className="lineOfBusinessTitle">
              Line Of Business
            </div>
            <div className="selectFormSizeControl">
              <Form.Control
                as="select"
                value={currentLineOfBusiness}
                onChange={this.props.changeLineOfBusiness}>
                {arrayOfLinesOfBusinesses}
              </Form.Control>
            </div>
          </Form.Group>
        </Form>;
    } else {
      return <div>
        <div className="lineOfBusinessTitle">
          Line Of Business
        </div>
        {linesOfBusiness[0]}
      </div>;
    }
  };

  render() {
    const lineOfBusinessElements = this.buildLineOfBusinessElement(
      this.props.linesOfBusiness,
      this.props.currentLineOfBusiness,
      this.props.readOnly,
    );
    return (
      <div className="lineOfBusiness">
        {lineOfBusinessElements}
      </div>
    );
  }
}
