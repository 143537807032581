import React, { Component } from 'react';
import {
  Container,
  Table,
  Col,
  Collapse,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EditAddLocationClosure from './EditAddLocationClosure';
import DescriptionEditModal from './DescriptionEditModal.js';
import Logger from '../modules/Logger.js';
import Description from '../modules/Description';
import EndMessage from './EndMessage';
import Enums from '../modules/Enums';
import TimeZone from 'moment-timezone';
import Utils from '../modules/Utils';
import Constants from '../modules/Constants';

import Locations from '../modules/Locations';

TimeZone.tz.setDefault(Constants.TimeZone.PACIFIC_TIME);

export default class LocationClosures extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationClosureMessages: [],
      showModal: false,
      currentMessageSelectedIndex: null,
      showEndLocationClosureMessage: false,
      currentSectionExpanded: true,
      expiredSectionExpanded: false,
      descriptionModal: false,
      description: null,
      idJwtToken: null,
    };
  }

  componentDidMount() {
    this.loadLocationClosureMessages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== undefined
      && prevProps.currentLineOfBusiness !== this.props.currentLineOfBusiness
      || prevProps.currentLocation !== this.props.currentLocation
    ) {
      this.loadLocationClosureMessages();
    }
  }

  async loadLocationClosureMessages() {
    if (this.props.currentLineOfBusiness === undefined
      || this.props.currentLocation === undefined) {
      return;
    }

    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {

      const contactCenter = Enums.LinesOfBusinessForAPI[
        this.props.currentLineOfBusiness
      ];
      const locationClosureMessages =
        await Locations.getLocationMessages(
          `${contactCenter}_${this.props.currentLocation}`,
          idJwtToken,
        );

      const description =
        await Description.getDescriptionMessage(
          'locationClosure',
          idJwtToken,
        );

      this.setState({
        locationClosureMessages,
        description,
        idJwtToken,
      });
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  }

  deleteLocalMessage = () => {
    const copyState = this.state.locationClosureMessages;
    const currentIndex = this.state.currentMessageSelectedIndex;
    const newState = copyState.filter((message, index) =>
      index !== currentIndex
    );

    this.setState({
      locationClosureMessages: [...newState],
    });
  }

  updateLocalMessage = (newMessage) => {
    let copyState = this.state.locationClosureMessages;
    this.state.currentMessageSelectedIndex === null
      ? copyState = [...copyState, newMessage]
      : copyState[this.state.currentMessageSelectedIndex] = newMessage;
    this.setState({
      locationClosureMessages: [...copyState],
    });
  }

  openModal = (index) => {
    this.setState({
      showModal: true,
      currentMessageSelectedIndex: index,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      currentMessageSelectedIndex: null,
    });
  }

  openEndMessageModal = (index) => {
    this.setState({
      showEndLocationClosureMessage: true,
      currentMessageSelectedIndex: index,
    });
  }

  closeEndMessageModal = () => {
    this.setState({
      showEndLocationClosureMessage: false,
      currentMessageSelectedIndex: null,
    });
  }

  toggleCurrentSection = () => {
    this.setState({
      currentSectionExpanded: !this.state.currentSectionExpanded,
    });
  }

  toggleExpiredSection = () => {
    this.setState({
      expiredSectionExpanded: !this.state.expiredSectionExpanded,
    });
  }

  openDescriptionEditModal = () => {
    this.setState({
      descriptionModal: true,
    });
  }

  closeDescriptionModal = () => {
    this.setState({
      descriptionModal: false,
    });
  }

  updateLocalDescription = (newDescription) => {
    this.setState({
      description: newDescription,
    });

    return true;
  }

  buildTableRows = (locationClosureMessages) => {
    const upcomingOrCurrent = [];
    const expired = [];
    const sortedLocationClosureMessages = Utils.sortMessagesByDate(
      locationClosureMessages
    );

    sortedLocationClosureMessages.map((message, index) => {
      const messageStatus = Utils.messageStatus(message);
      const startMonthDayYear = TimeZone(Number(message.startDateTime))
        .format(Constants.DateFormat.MONTH_DAY_YEAR);
      const startTime = TimeZone(Number(message.startDateTime))
        .format(Constants.DateFormat.TIME);
      const endMonthDayYear = TimeZone(Number(message.endDateTime))
        .format(Constants.DateFormat.MONTH_DAY_YEAR);
      const endTime = TimeZone(Number(message.endDateTime))
        .format(Constants.DateFormat.TIME);

      const value = <tr key={index}>
        <td className="iconColumn">
          <FontAwesomeIcon
            className="editIcon action-icon mr-2"
            icon="pen"
            onClick={() => this.openModal(index)}
          />
          <FontAwesomeIcon
            className="trash-alt action-icon"
            icon="trash-alt"
            onClick={() => this.openEndMessageModal(index)}
          />
        </td>

        <td className="descriptionColumn">{message.description}</td>
        <td className="dateColumn">{startMonthDayYear}</td>
        <td className="timeColumn">{startTime}</td>
        <td className="dateColumn">{endMonthDayYear}</td>
        <td className="timeColumn">{endTime}</td>
      </tr>;

      if (messageStatus === 'UPCOMING' || messageStatus === 'CURRENT') {
        upcomingOrCurrent.push(value);
      } else {
        expired.push(value);
      }
    });

    return {
      upcomingOrCurrent,
      expired,
    };
  }

  editAddLocationClosuredMessages = (index) => {

    return <EditAddLocationClosure
      modalStatus={this.state.showModal}
      closeModal={this.closeModal}
      locationClosureMessage={this.state.locationClosureMessages[index]}
      currentLineOfBusiness={this.props.currentLineOfBusiness}
      currentLocation={this.props.currentLocation}
      updateLocalMessage={this.updateLocalMessage}
      userName={this.props.userName}
    />;
  }

  endMessage = (index) => {
    const idJwtToken = this.state.idJwtToken;

    if (idJwtToken) {
      const textValues = {
        title: 'Delete Location Closure Message',
        message: 'Are you sure you want to delete the ' +
          'location closure message?',
        buttonText: 'Delete Closure Message',
      };

      const updateFunction = () => Locations.deleteLocationClosureMessage(
        {
          LOB: `${Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness]}_${this.props.currentLocation}`,
          message: this.state.locationClosureMessages[index],
          idJwtToken,
        }
      );

      return <EndMessage
        endModalStatus={this.state.showEndLocationClosureMessage}
        closeModal={this.closeEndMessageModal}
        message={this.state.locationClosureMessages[index]}
        updateFunction={updateFunction}
        textValues={textValues}
        updateLocalMessage={this.deleteLocalMessage}
      />;
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
      return null;
    }
  }

  render() {
    const tableValues = this.state.locationClosureMessages
      ? this.buildTableRows(this.state.locationClosureMessages)
      : null;
    const editModal = this.state.showModal
      ? this.editAddLocationClosuredMessages(
        this.state.currentMessageSelectedIndex
      )
      : null;
    const endModal = this.state.showEndLocationClosureMessage
      ? this.endMessage(this.state.currentMessageSelectedIndex)
      : null;

    const currentStateClass = this.state.currentSectionExpanded
      ? ''
      : 'collapsed';

    const expiredStateClass = this.state.expiredSectionExpanded
      ? ''
      : 'collapsed';

    const descriptionModal = this.state.descriptionModal
      ? <DescriptionEditModal
        modalStatus={this.state.descriptionModal}
        closeModal={this.closeDescriptionModal}
        currentLineOfBusiness={this.props.currentLineOfBusiness}
        updateLocalMessage={this.updateLocalDescription}
        description={this.state.description}
        title={'Location Closure Description'}
        messageType={'locationClosure'}
      />
      : null;

    return (
      <div className="planned-messages">
        <Container className="containerBody mb-2">
          <div className="businessHoursTitle">
            Location Closures (PST)
            <OverlayTrigger trigger="click" placement="right" overlay={
              <Popover
                id="popover-basic" title="Location Closure Description">
                {this.state.description}
              </Popover>
            }>
              <span>
                <FontAwesomeIcon
                  className="editIcon action-icon mr-2"
                  icon="comment"
                  variant="success"
                />
              </span>
            </OverlayTrigger>
            {this.props.permissions.isGlobalAdmin
              ? <FontAwesomeIcon
                className="editIcon action-icon mr-2"
                icon="pen"
                onClick={() => this.openDescriptionEditModal()}
              /> : null}
          </div>


          <div className={currentStateClass}>
            <div className="formLabel section-header"
              onClick={this.toggleCurrentSection}>
              <FontAwesomeIcon
                className="caret-down"
                icon="caret-down"
              />
              Current and Future
            </div>
            <Col>
              <Collapse
                in={this.state.currentSectionExpanded}>
                <div>
                  <div className="formLabel add-planned-message-link"
                    onClick={() => this.openModal(null)}
                    role="link"
                    tabIndex={0}>
                    <FontAwesomeIcon
                      className="plus add-planned-message-icon"
                      icon="plus"
                    />
                    Add Location Closure
                  </div>
                  <Table striped hover responsive size="sm">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          Description
                        </th>

                        <th>
                          Start Date
                        </th>
                        <th>
                          Start Time
                        </th>
                        <th>
                          End Date
                        </th>
                        <th>
                          End Time
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableValues
                        ? tableValues.upcomingOrCurrent
                        : null
                      }
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </Col>
          </div>
          <div className={expiredStateClass}>
            <div className="formLabel section-header"
              onClick={this.toggleExpiredSection}>
              <FontAwesomeIcon
                className="caret-down"
                icon="caret-down"
              />
              Expired
            </div>
            <Col>
              <Collapse in={this.state.expiredSectionExpanded}>
                <div>
                  <Table striped hover responsive size="sm">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          Description
                        </th>

                        <th>
                          Start Date
                        </th>
                        <th>
                          Start Time
                        </th>
                        <th>
                          End Date
                        </th>
                        <th>
                          End Time
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableValues
                        ? tableValues.expired
                        : null
                      }
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </Col>
          </div>
        </Container>
        {editModal}
        {endModal}
        {descriptionModal}
      </div>
    );
  }
}
