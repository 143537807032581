const LINE_BREAK = "\r\n";
const MAX_REQUEST_SIZE = 3000;

const splitIntoBatches = (header, elements) => {
  let batches = [];

  // The size of the largest element
  const maxElementLength = Math.max(...elements.map((el) => el.length));

  // The batch always starts out with a header
  let batch = `${header}${LINE_BREAK}`;

  // For all the elements
  for (const element of elements) {
    // Add the element to the batch
    batch = `${batch}${element}${LINE_BREAK}`;

    // If adding another element to the batch (longest one) overflows the max request size, push to the array and reset for a new batch
    if (
      getRequestSize(batch) + maxElementLength + getRequestSize(header) >=
      MAX_REQUEST_SIZE
    ) {
      batches.push(batch);
      batch = `${header}${LINE_BREAK}`;
    }
  }

  // If there is a valid batch left (not just a single header row left), add it. The "+4" is used for the \r\n.
  if (batch && batch.length > header.length + 4) {
    batches.push(batch.trim());
  }

  return batches;
};

const getRequestSize = (request) => {
  return Buffer.from(request).length;
};

export const getBatches = (request) => {
  let batches = [];

  // The request size in bytes
  const requestSize = getRequestSize(JSON.stringify(request));

  // If the request size is greater than the maximum size allowed
  if (requestSize > MAX_REQUEST_SIZE) {
    // Each element is on a single line
    const elements = request.split(LINE_BREAK);

    // Remove the header, since the header will be re-added to all batches
    const [, ...elementsWithoutHeader] = elements;

    // Split the large request into multiple even sized batches
    batches = splitIntoBatches(elements[0], elementsWithoutHeader);
  } else {
    batches.push(request);
  }
  return batches;
};
