import React, { Component } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import LineOfBusiness from './LineOfBusiness';
import Logger from '../modules/Logger.js';
import Utils from '../modules/Utils';
import TemporaryMessages from '../modules/TemporaryMessages';
import Enums from '../modules/Enums';
import Constants from '../modules/Constants';

import DateTime from 'react-datetime';

export default class EditTempMesssageModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      saveInProgress: false,
      ...this.formatTempMessageState(this.props.tempMessage),
    };
  }

  TempMessageKeyNames = {
    ENGLISH: 'englishResponse',
    FRENCH: 'frenchResponse',
    START_IMMEDIATE: 'startImmediate',
    START_SET_TIME: 'startSetTime',
    START_DATE_TIME: 'startDateTime',
    END_UNKNOWN: 'endUnknown',
    END_SET_TIME: 'endSetTime',
    END_DATE_TIME: 'endDateTime',
  };

  updateTempMessage = async (newMessage) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      const isUpdateSuccess = await TemporaryMessages
        .updateTempMessage({
          LOB: Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness],
          index: this.props.index,
          message: newMessage,
          idJwtToken,
        });

      return isUpdateSuccess;
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  };

  formatTempMessageState = (tempMessage) => {
    if (!tempMessage) {
      return {
        formisValid: true,
        descriptionIsValid: true,
        dateAndTimeIsValid: true,
        startImmediate: true,
        startSetTime: false,
        startDateTime: Date.now(),
        endUnknown: true,
        endSetTime: false,
        endDateTime: Date.now(),
        englishResponseType: 'AUDIO',
        frenchResponseType: 'AUDIO',
        responseIsValid: true,
        description: '',
        type: 'TEMPORARY',
        category: 'TEMPORARY',
        englishResponse: '',
        frenchResponse: '',
        updateSucessful: true,
        endTimeValid: true,
      };
    } else {
      const stateObj = {
        formisValid: true,
        descriptionIsValid: true,
        dateAndTimeIsValid: true,
        englishResponseType: tempMessage.response_en.type,
        frenchResponseType: tempMessage.response_fr.type,
        responseIsValid: true,
        description: tempMessage.description,
        type: tempMessage.type,
        category: tempMessage.category,
        englishResponse: tempMessage.response_en.value,
        frenchResponse: tempMessage.response_fr.value,
        updateSucessful: true,
        endTimeValid: true,
      };

      if (tempMessage.startDateTime) {
        stateObj.startImmediate = false;
        stateObj.startSetTime = true;
        stateObj.startDateTime = Number(tempMessage.startDateTime);
      }

      if (tempMessage.endDateTime !== null) {
        stateObj.endUnknown = false;
        stateObj.endSetTime = true;
        stateObj.endDateTime = Number(tempMessage.endDateTime);
      } else {
        stateObj.endUnknown = true;
        stateObj.endSetTime = false;
        stateObj.endDateTime = Date.now();
      }

      return stateObj;
    }
  }

  controlRadioButtonSelections = (buttonSelected, state) => {
    let selectionObj = {};

    switch (buttonSelected) {
      case this.TempMessageKeyNames.START_IMMEDIATE:
        state.endUnknown
          ? selectionObj = {
            dateAndTimeIsValid: true,
            startImmediate: true,
            startSetTime: false,
          }
          : selectionObj = {
            startImmediate: true,
            startSetTime: false,
          };
        return selectionObj;

      case this.TempMessageKeyNames.START_SET_TIME:
        return selectionObj = {
          startImmediate: false,
          startSetTime: true,
        };

      case this.TempMessageKeyNames.END_UNKNOWN:
        return {
          dateAndTimeIsValid: true,
          endUnknown: true,
          endSetTime: false,
        };

      default:
        return selectionObj = {
          endUnknown: false,
          endSetTime: true,
        };
    }
  }

  handleValidation = () => {
    const currentTimeSelectionObj = {
      startImmediateSelected: this.state.startImmediate,
      endUnknownSelected: this.state.endUnknown,
      selectedStartTime: this.state.startDateTime,
      selectedEndTime: this.state.endDateTime,
      currentTime: Date.now(),
    };

    const isDescriptionValid = Utils.isDescriptionValid(
      this.state.description
    );
    const isDateAndTimeValid = Utils.isDateAndTimeValid(
      currentTimeSelectionObj
    );
    const isResponseValid = Utils.isResponseValid(
      this.state.englishResponse,
      this.state.frenchResponse,
    );

    const isEndTimeValid = Utils.isEndTimeValid(
      currentTimeSelectionObj
    );

    const isFormValid = Utils.isFormValid(
      isDescriptionValid,
      isDateAndTimeValid,
      isResponseValid,
      isEndTimeValid
    );

    return {
      descriptionIsValid: isDescriptionValid,
      dateAndTimeIsValid: isDateAndTimeValid,
      responseIsValid: isResponseValid,
      endTimeValid: isEndTimeValid,
      formisValid: isFormValid,
    };
  }

  handleSubmit = async (event) => {
    if (this.state.saveInProgress) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    this.setState({ saveInProgress: true });

    const checkValidation = this.handleValidation();

    if (checkValidation.formisValid) {
      const newTempMessage = Utils.formatTempMessageToAPI(this.state,
        this.props.userName);

      const isUpdateSuccess = await this.
        updateTempMessage(newTempMessage);

      if (isUpdateSuccess) {
        this.props.updateLocalTempMessage(newTempMessage);
        this.props.closeModal();
      } else {
        this.setState({
          updateSucessful: false,
          saveInProgress: false,
        });
      }
    } else {
      this.setState({
        descriptionIsValid: checkValidation.descriptionIsValid,
        dateAndTimeIsValid: checkValidation.dateAndTimeIsValid,
        responseIsValid: checkValidation.responseIsValid,
        formisValid: checkValidation.formisValid,
        endTimeValid: checkValidation.endTimeValid,
        updateSucessful: true,
        saveInProgress: false,
      });
    }
  }

  handleDescriptionChange = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const descriptionIsValid = Utils.isDescriptionValid(event.target.value);

    this.setState({
      descriptionIsValid: descriptionIsValid,
      description: event.target.value,
    });
  }

  handleRadioButtons = (event) => {
    const button = event.target.value;
    const select = this.controlRadioButtonSelections(button, this.state);
    this.setState(select);
  }

  handleDateAndTime = (isStart, event, date) => {
    const dateAndTime = Date.parse(date);

    let currentTimeSelectionObj = {
      startImmediateSelected: this.state.startImmediate,
      endUnknownSelected: this.state.endUnknown,
      currentTime: Date.now(),
    };

    isStart
      ? currentTimeSelectionObj = {
        ...currentTimeSelectionObj,
        selectedStartTime: dateAndTime,
        selectedEndTime: this.state.endDateTime,
      }
      : currentTimeSelectionObj = {
        ...currentTimeSelectionObj,
        selectedStartTime: this.state.startDateTime,
        selectedEndTime: dateAndTime,
      };

    const dateAndTimeIsValid = Utils.isDateAndTimeValid(
      currentTimeSelectionObj
    );

    const isEndTimeValid = Utils.isEndTimeValid(
      currentTimeSelectionObj
    );

    this.setState({
      dateAndTimeIsValid,
      [isStart
        ? this.TempMessageKeyNames.START_DATE_TIME
        : this.TempMessageKeyNames.END_DATE_TIME
      ]: dateAndTime,
      endTimeValid: isEndTimeValid,
    });
  }

  handleResponseTypeChange = (event) => {
    this.setState({
      //englishResponseType or frenchResponseType
      [event.target.name]: event.target.value,
    });
  }

  handleResponseMessage = (language, event) => {
    const message = event.target.value;

    const otherLanguage = language === Enums.Language.ENGLISH
      ? Enums.Language.FRENCH
      : Enums.Language.ENGLISH;

    const otherLanguageValue = this.state[this
      .TempMessageKeyNames[otherLanguage]];

    const responseIsValid = Utils.isResponseValid(message, otherLanguageValue);

    this.setState({
      responseIsValid,
      [this.TempMessageKeyNames[language]]: message,
    });
  }

  handleClose = () => {
    this.props.closeModal();
  }

  getTitle = () => {
    const currentOrExpiredOrUpcoming = Utils.messageStatus(
      this.props.tempMessage
    );
    switch (currentOrExpiredOrUpcoming) {
      case 'UPCOMING':
      case 'CURRENT':
        return 'Update Temporary Message';

      case 'EXPIRED':
      default:
        return 'Create Temporary Message';
    }
  }

  getSaveButton = () => {
    return this.state.saveInProgress
      ? <Button
        disabled
        type="submit"
        className="submitCloseButtons">
        Saving...
      </Button>
      : <Button
        type="submit"
        className="submitCloseButtons">
        Save
      </Button>;
  }

  getCloseButton = () => {
    return this.state.saveInProgress
      ? <Button
        disabled
        variant="outline-secondary"
        onClick={this.handleClose}
        className="submitCloseButtons">
        Close
      </Button>
      : <Button
        variant="outline-secondary"
        onClick={this.handleClose}
        className="submitCloseButtons">
        Close
      </Button>;
  }

  render() {
    const modalTitle = this.getTitle();
    const saveButton = this.getSaveButton();
    const closeButton = this.getCloseButton();

    return (
      <div>
        <Modal show={this.props.modalStatus} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>


            <Form
              noValidate
              onSubmit={this.handleSubmit}
              className="conatiner">
              <div className="formSection noBorder">
                <LineOfBusiness
                  linesOfBusiness={[this.props.currentLineOfBusiness]}
                  currentLineOfBusiness={this.props.currentLineOfBusiness}
                  readOnly={true}
                  className="col-12"
                />

                <Form.Group controlId="Category" className="formGroup">
                  <Form.Label
                    className="formLabel">
                    Category
                  </Form.Label>
                  <div>
                    {this.state.category}
                  </div>
                </Form.Group>

                <Form.Group controlId="Description" className="formGroup">
                  <Form.Label
                    className="formLabel">
                    Description
                  </Form.Label>

                  <Form.Control
                    required
                    type="text"
                    readOnly={!this.props.permissions.isGlobalAdmin}
                    defaultValue={this.state.description}
                    onChange={this.handleDescriptionChange}
                    name="Description"
                  />
                </Form.Group>
                <div className={this.state.descriptionIsValid
                  ? 'removeContent'
                  : 'displayContent validateForm'}>
                  Please make sure the description length is between 1 to 50 characters.
                </div>
              </div>
              <h5 className="formSectionHeader">Time</h5>
              <div className="formSection container">
                <Form.Group controlId="startEmergencyTime">
                  <Form.Label className="formLabel">
                    Start Time
                  </Form.Label>
                  <div className="emergencyMessageDatePickerradioButtons">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Immediate"
                      value="startImmediate"
                      checked={this.state.startImmediate}
                      onChange={this.handleRadioButtons}
                    />

                    <Form.Check
                      type="radio"
                      aria-label="radio 2"
                      label="Set Time"
                      value="startSetTime"
                      checked={this.state.startSetTime}
                      onChange={this.handleRadioButtons}
                    />
                  </div>

                  <div className={this.state.startSetTime
                    ? 'displayContent mt-2'
                    : 'removeContent'}>
                    <DateTime
                      defaultValue={
                        new Date(Number(this.state.startDateTime))
                      }
                      timeFormat={
                        Constants.DateFormat.TIME
                      }
                      name="startDateTime"
                      className="dateTimePicker"
                      onChange={this.handleDateAndTime.bind(
                        this, true, this.name)}
                    />
                  </div>
                </Form.Group>

                <Form.Group controlId="endEmergencyTime">
                  <Form.Label className="formLabel">
                    End Time
                  </Form.Label>
                  <div className="emergencyMessageDatePickerradioButtons">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Undetermined"
                      value="endUnknown"
                      checked={this.state.endUnknown}
                      onChange={this.handleRadioButtons} />

                    <Form.Check
                      type="radio"
                      aria-label="radio 2"
                      label="Set Time"
                      value="endSetTime"
                      checked={this.state.endSetTime}
                      onChange={this.handleRadioButtons} />
                  </div>

                  <div className={this.state.endSetTime
                    ? 'displayContent mt-2'
                    : 'removeContent'}>
                    <DateTime
                      defaultValue={
                        new Date(Number(this.state.endDateTime))
                      }
                      timeFormat={
                        Constants.DateFormat.TIME
                      }
                      name="endDateTime"
                      className="dateTimePicker"
                      onChange={this.handleDateAndTime.bind(
                        this, false, this.name
                      )}
                    />
                  </div>
                  <div className={this.state.dateAndTimeIsValid
                    ? 'removeContent'
                    : 'displayContent validateForm'}>
                    Please make sure the end date is after the start date.
                  </div>

                  <div className={this.state.endTimeValid
                    ? 'removeContent'
                    : 'displayContent validateForm'}>
                    The end date and time must be after today.
                  </div>
                </Form.Group>
              </div>
              <h5 className="formSectionHeader">Response</h5>
              <div className="formSection container mb-2">

                <Form.Group
                  controlId="englishResponse"
                  className="emergencyMessageResponseInputText">
                  <Form.Label
                    className="formLabel">
                    English (en_US)
                  </Form.Label>

                  <div className="formGroup">
                    <Form.Label
                      className="formLabel">
                      English Response Type
                    </Form.Label>

                    <div className="selectFormSizeControl">
                      <Form.Control
                        as="select"
                        value={this.state.englishResponseType}
                        name="englishResponseType"
                        onChange={this.handleResponseTypeChange}>
                        <option>
                          AUDIO
                        </option>
                        <option>
                          TTS
                        </option>
                        <option>
                          SSML
                        </option>
                      </Form.Control>
                    </div>
                  </div>

                  <Form.Control
                    required
                    type="text"
                    defaultValue={this.state.englishResponse}
                    onChange={this.handleResponseMessage.bind(
                      this, Enums.Language.ENGLISH
                    )}
                    name="englishResponse"
                  />
                </Form.Group>

                <Form.Group
                  controlId="frenchResponse"
                  className="emergencyMessageResponseInputText">
                  <Form.Label
                    className="formLabel">
                    Canadian French (fr_CA)
                  </Form.Label>

                  <div className="formGroup">
                    <Form.Label
                      className="formLabel">
                      French Response Type
                    </Form.Label>

                    <div className="selectFormSizeControl">
                      <Form.Control
                        as="select"
                        value={this.state.frenchResponseType}
                        name="frenchResponseType"
                        onChange={this.handleResponseTypeChange}>
                        <option>
                          AUDIO
                        </option>
                        <option>
                          TTS
                        </option>
                        <option>
                          SSML
                        </option>
                      </Form.Control>
                    </div>
                  </div>

                  <Form.Control
                    required
                    type="text"
                    defaultValue={this.state.frenchResponse}
                    onChange={this.handleResponseMessage.bind(
                      this, Enums.Language.FRENCH
                    )}
                    name="frenchResponse"
                  />
                </Form.Group>

                <div className={this.state.responseIsValid
                  ? 'removeContent'
                  : 'displayContent validateForm'}>
                  Please make sure the length of responses are between 1 to 1000 characters.
                </div>
              </div>
              {saveButton}
              {closeButton}

              <div className={
                this.state.updateSucessful
                  ? 'removeContent'
                  : 'displayContent validateForm'}>
                Updating Temporary message was not successful. Please try again.
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
