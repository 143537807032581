import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import RealTimeTable from './RealTimeTable';

export default class RealTimeTabsOfContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Tabs
        id="realtime"
        activeKey={this.state.key}
        className="nav-tabs">

        <Tab
          eventKey="realtime"
          title="Setup">
          <RealTimeTable
            currentLineOfBusiness={this.props.currentLineOfBusiness}
            userName={this.props.userName}
            permissions={this.props.permissions}
					/>
        </Tab>
      </Tabs>
    );
  }
}
