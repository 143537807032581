const LogLevel = {
  WARNING: '[WARNING] :',
  INFO: '[INFO] :',
  ERROR: '[ERROR] :',
  FATAL: '[FATAL] :',
  VERBOSE: '[VERBOSE] :',
};

const LinesOfBusinessForAPI = {
  FCC: '1',
  PCC: '2',
  STSD: '3',
  LSR: '4',
};

const LinesofBusinessForUI = {
  1: 'FCC',
  2: 'PCC',
  3: 'STSD',
  4: 'LSR',
};

const LocationForAPI = {
  Sitel: 'sitel',
  Fcc: 'fcc',
};

const Language = {
  ENGLISH : 'ENGLISH',
  FRENCH: 'FRENCH',
};

const MessageCategory = {
  HOLIDAY: 'Holiday',
  MAINTENANCE: 'Maintenance',
  MEETING: 'Meeting',
  RECURRING_MEETING: 'Recurring Meeting',
  Holiday: 'HOLIDAY',
  Maintenance: 'MAINTENANCE',
  Meeting: 'MEETING',
  'Recurring Meeting': 'RECURRING_MEETING',
};

const MessageType = {
  SITUATIONAL: 'Situational',
  Situational: 'SITUATIONAL',
  Emergency: 'EMERGENCY',
  Temporary: 'TEMPORARY',
  EXPIRED: 'Disabled',
  UPCOMING: 'Scheduled',
  CURRENT: 'Active',
  Closure: 'CLOSURE',
  CLOSURE: 'Closure',
};

const UtteranceStatus = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  DELETED: 'DELETED',
};

module.exports = {
  LogLevel: LogLevel,
  LinesOfBusinessForAPI: LinesOfBusinessForAPI,
  LinesofBusinessForUI: LinesofBusinessForUI,
  Language,
  MessageCategory,
  MessageType,
  LocationForAPI,
  UtteranceStatus,
};
