import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

export default class EndMessageModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      saveInProgress: false,
      updateSucessful: true,
    };
  }

  confirmDelete = async () => {
    this.setState({saveInProgress: true});

    const isUpdateSuccess = await this.props.updateFunction();

    if (isUpdateSuccess) {
      this.props.updateLocalMessage(
        this.props.message,
      );

      this.props.closeModal();
    } else {
      //TODO: Something went wrong.  Please refresh and try again.
      this.setState({
        saveInProgress: false,
        updateSucessful: false,
      });
    }
  }

  cancelDelete = () => {
    this.props.closeModal();
  }

  getEndButton = (buttonText) => {
    return this.state.saveInProgress
      ? <Button
        disabled
        variant="danger">
        Updating...
      </Button>
      : <Button
        variant="danger"
        onClick={this.confirmDelete}>
        {buttonText}
      </Button>;
  }

  render() {
    const endButton = this.getEndButton(this.props.textValues.buttonText);
    const message = this.props.warningMessage
      ? this.props.warningMessage
      : null;
    return (
      <Modal show={this.props.endModalStatus}
        onHide={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.textValues.title}
            {message}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.textValues.message}
        </Modal.Body>

        <Modal.Footer>
          {endButton}

          <Button variant="outline-secondary" onClick={this.cancelDelete}>
            Close
          </Button>

          <div className={
            this.state.updateSucessful
              ? 'removeContent'
              : 'displayContent validateForm'}>
            Updating message was not successful. Please try again.
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
