import Settings from './Settings.js';
import Logger from './Logger.js';
import apigClientFactory from 'aws-api-gateway-client';

export default class RecurringMessages  {

  static getLOBLocationMessages = (LOB) => {
    return this.starbucksAPIURL + '/lob/' + LOB + '/locationclosures';
  }

  static get = async (lobId, idJwtToken) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/${lobId}/recurringmessage`;
    const method = 'GET';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {};

    const response = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    Logger.verbose(JSON.stringify(response));
    return response.data.recurringMessages;
  };

  static updateSituationalMessage = async (
    LOB,
    previousSituationalMessage,
    updatedSituationalMessage,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/${LOB}/situationalmessage`;
    const method = 'POST';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      previousSituationalMessage,
      updatedSituationalMessage,
    };

    try {
      const updateSituationalMessages = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );

      Logger.verbose(JSON.stringify(
        `Line of Business updated: ${updateSituationalMessages.data.LineOfBusiness}`));
      return true;
    } catch (err) {
      Logger.error('Exception Update situational message: ' +
        JSON.stringify(err));
      return false;
    }
  }

  static deleteSituationalMessage = async ({
    LOB,
    message,
    idJwtToken,
  }) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/${LOB}/situationalmessage`;
    const method = 'DELETE';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      deleteSituationalMessage: message,
    };

    try {
      const deletedSituationalMessage = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );

      Logger.verbose(JSON.stringify(
        `Line of Business updated: ${deletedSituationalMessage.data.LineOfBusiness}`));
      return true;
    } catch (err) {
      Logger.error('Exception deleting situational message: ' +
        JSON.stringify(err));
      return false;
    }
  }

  static deleteRecurringMessage = async ({
    lobId,
    recurringMessageId,
    idJwtToken,
  }) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/${lobId}/recurringmessage/${recurringMessageId}`;
    const method = 'DELETE';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {};

    try {
      const deleteRecurring = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `Recurring message id deleted: ${recurringMessageId}`));
      return true;
    } catch (err) {
      Logger.error('Exception deleting recurring message: ' +
        JSON.stringify(err));
      return false
    }
  }

  static postRecurringMessage = async (
    lobId,
    recurringMessage,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/${lobId}/recurringmessage`;
    const method = 'POST';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      ...recurringMessage,
    };

    try {
      const post = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );
      Logger.verbose(JSON.stringify(
        `Recurring Message updated: ${post.data.recurringMessageId}`));
      return true;
    } catch (err) {
      Logger.error('Exception updating recurring message: ' +
        JSON.stringify(err));
      return false;
    }
  }
}
