export default class Constants {
  static TimeZone = {
    PACIFIC_TIME: 'America/Los_Angeles',
  }

  static DateFormat = {
    FULL_DATE: 'MM/DD/YY h:mm a [PST]',
    MONTH_DAY_YEAR: 'MM/DD/YY',
    TIME: 'h:mm a [PST]',
    ALIASPUBLISHED_DATE: 'h:mm a MM/DD/YY [PST]',
  }

  static TextLength = {
    MAX: 1000,
  }
}
