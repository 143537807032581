import React, { Component } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Logger from '../modules/Logger.js';
import Agents from '../modules/Agents';
import Cleave from 'cleave.js/react';
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us';


import LoadingCoffeeCup from
  '@starbucks/pattern-library/lib/components/loading-coffee-cup';
import Rule from '@starbucks/pattern-library/lib/components/rule';
import Animator from '@starbucks/pattern-library/lib/components/animator';

export default class AgentsTableModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveInProgress: false,
      agent: null,
      isLoading: true,
      firstName: '',
      lastName: '',
      routing: '',
      routingList: [],
      routingFromApi: [],
      security: [],
      securityList: [],
      securityFromApi: [],
      hierarchy: '',
      hierarchyListFromApi: [],
      hierarchyFiltered: [],
      deskPhone: '',
      newDeskPhone: '',
      phonePrefix: '+1',
      phoneType: '',
      workTimeOut: 0,
      autoAccept: false,
      errorMessage: '',
    };
    this.onPhoneChange = this.onPhoneChange.bind(this);
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData = async () => {
    this.setState({
      isLoading: true,
    });
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    try {
      if (idJwtToken) {
        const securityFromApi =
          await Agents.
            getSecurityList(
            );
        const routingFromApi =
          await Agents.
            getRoutingList(
            );
        const agent =
          await Agents.
            getUserModal(
              this.props.amazonId,
              idJwtToken,
            );
        this.setState({
          agent,
          securityFromApi,
          routingFromApi,
        });
      } else {
        Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
        this.props.closeModal();
      }
      const newUser = await this.state.agent.User;
      this.filterSecurityList();
      this.filterRoutingList();
      this.setState({
        firstName: newUser.IdentityInfo.FirstName,
        lastName: newUser.IdentityInfo.LastName,
        deskPhone: newUser.PhoneConfig.DeskPhoneNumber.slice(2),
        phoneType: newUser.PhoneConfig.PhoneType,
        routing: newUser.RoutingProfileId,
        security: newUser.SecurityProfileIds,
        hierarchy: newUser.HierarchyGroupId,
        workTimeOut: newUser.PhoneConfig.AfterContactWorkTimeLimit,
        autoAccept: newUser.PhoneConfig.AutoAccept,
        isLoading: false,
      });
    } catch (error) {
      console.log('An error has occurred while fetching user data', error);
      this.props.closeModal();
    }
  }

  filterDeskPhone = () => {
    if (this.state.deskPhoneRaw[0] === '+') {
      const filteredPhone = this.state.deskPhoneRaw.slice(0, 1);
      this.setState({ deskPhone: filteredPhone });
    } else {
      this.setState({ deskPhone: this.state.deskPhoneRaw });
    }
  }

  filterSecurityList = () => {
    const listFromApi = this.state.securityFromApi.SecurityProfileSummaryList;
    const securityListFiltered = listFromApi.filter(list =>
      (this.props.permissions.isGlobalAdmin &&
        (list.Name.includes('Admin') || list.Name.includes('QualityAnalyst')
          || list.Name.includes('CallCenterManager')))
      || list.Name.includes(this.props.currentLineOfBusiness)
    );
    this.setState({
      securityList: securityListFiltered,
    });
  }

  filterRoutingList = () => {
    const listFromApi = this.state.routingFromApi.RoutingProfileSummaryList;
    const routingListFiltered = listFromApi.filter((list) => {
      if ((this.state.agent && list.Name.includes('Basic'))
        || list.Name.includes(this.props.currentLineOfBusiness)
      ) {
        return true;
      }
    });
    this.setState({
      routingList: routingListFiltered,
    });
  }

  formatErrorMessage = (errorMessage) => {
    const errorIndex = errorMessage[0].indexOf(':');
    const errorMessageSlice =
      `Server error${errorMessage[0].slice(errorIndex)}`;
    this.setState({
      errorMessage: errorMessageSlice,
    });
  }

  handleUpdateUser = async () => {
    if (this.state.saveInProgress) {
      return;
    }
    this.setState({
      saveInProgress: true,
    });
    const isUpdateIdSuccess = await this
      .updateUserIdentity(
        this.state.firstName,
        this.state.lastName,
      );
    const isUpdateRoutingSuccess = await this
      .updateUserRoutingProfile(
        this.state.routing,
      );
    const isUpdatePhoneSuccess = await this
      .updateUserPhoneConfig(
        `${this.state.phonePrefix}${this.state.deskPhone}`,
        this.state.phoneType,
        this.state.workTimeOut,
        this.state.autoAccept,
      );
    const isUpdateSoftPhoneSuccess = await this
      .updateUserSoftPhoneConfig(
        this.state.phoneType,
        this.state.workTimeOut,
        this.state.autoAccept,
      );
    const isUpdateSecuritySuccess = await this
      .updateUserSecurityProfile(
        this.state.security,
      );
    if (isUpdateIdSuccess && isUpdatePhoneSuccess && isUpdateRoutingSuccess
      && isUpdateSecuritySuccess && isUpdateSoftPhoneSuccess) {
      this.props.closeModalWithConfirmation();
    } else {
      this.getUserData();
      this.setState({
        saveInProgress: false,
      });
    }
  }

  updateUserIdentity = async (
    FirstName,
    LastName,
  ) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    const { User } = this.state.agent;
    if (idJwtToken && (this.state.firstName !== User.IdentityInfo.FirstName ||
      this.state.lastName !== User.IdentityInfo.LastName)) {
      const isUpdateSuccess = await Agents.
        updateUserIdentity(
          this.props.amazonId,
          FirstName,
          LastName,
          idJwtToken,
        );
      return isUpdateSuccess;
    } else if (!idJwtToken) {
      Logger.verbose(JSON.stringify(
        `JWT TOKEN for User Identity is null: ${idJwtToken}`
      ));
      return null;
    } else {
      return true;
    }
  }

  updateUserPhoneConfig = async (
    deskPhone,
    softOrDesk,
    workTimeOut,
    autoAccept,
  ) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    const { PhoneConfig } = this.state.agent.User;
    if (idJwtToken && (this.state.phoneType === 'DESK_PHONE') &&
      ((this.state.deskPhone !== PhoneConfig.DeskPhoneNumber) ||
        (this.state.workTimeOut !== PhoneConfig.AfterContactWorkTimeLimit) ||
        (this.state.autoAccept !== PhoneConfig.AutoAccept))) {
      const isUpdateSuccess = await Agents.
        updateUserPhoneConfig(
          this.props.amazonId,
          deskPhone,
          softOrDesk,
          workTimeOut,
          autoAccept,
          idJwtToken,
        );
      if ((Object.keys(isUpdateSuccess).includes('ClientError'))) {
        const errorMessage = Object.values(isUpdateSuccess);
        this.formatErrorMessage(errorMessage);
        return false;
      } else {
        return isUpdateSuccess;
      }
    } else {
      return true;
    }
  }
  updateUserSoftPhoneConfig = async (
    softOrDesk,
    workTimeOut,
    autoAccept,
  ) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    const { PhoneConfig } = this.state.agent.User;
    if (idJwtToken && (this.state.phoneType === 'SOFT_PHONE') &&
      ((this.state.deskPhone !== PhoneConfig.DeskPhoneNumber) ||
        (this.state.workTimeOut !== PhoneConfig.AfterContactWorkTimeLimit) ||
        (this.state.autoAccept !== PhoneConfig.AutoAccept))) {
      const isUpdateSuccess = await Agents.
        updateUserSoftPhoneConfig(
          this.props.amazonId,
          softOrDesk,
          workTimeOut,
          autoAccept,
          idJwtToken,
        );
      if ((Object.keys(isUpdateSuccess).includes('ClientError'))) {
        const errorMessage = Object.values(isUpdateSuccess);
        this.formatErrorMessage(errorMessage);
        return false;
      } else {
        return isUpdateSuccess;
      }
    } else {
      return true;
    }
  }

  updateUserRoutingProfile = async (routing) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    if (idJwtToken && this.state.routing !==
      this.state.agent.User.RoutingProfileId) {
      const isUpdateSuccess = await Agents.
        updateUserRoutingProfile(
          this.props.amazonId,
          routing,
          idJwtToken,
        );
      return isUpdateSuccess;
    } else if (!idJwtToken) {
      Logger.verbose(JSON.stringify(
        `JWT TOKEN for Routing Id is null: ${idJwtToken}`
      ));
      return null;
    } else {
      return true;
    }
  }

  updateUserSecurityProfile = async (security) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    if (idJwtToken) {
      const isUpdateSuccess = await Agents.
        updateUserSecurityProfile(
          this.props.amazonId,
          security,
          idJwtToken,
        );
      return isUpdateSuccess;
    } else if (!idJwtToken) {
      Logger.verbose(JSON.stringify(
        `JWT TOKEN for Security Profile is null: ${idJwtToken}`
      ));
      return null;
    }
  }

  handleClick = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  }

  handleDeskPhone = (e) => {
    this.setState({
      newDeskPhone: e.target.value,
      deskPhone: e.target.value,
    });
  }

  handleTimeout = (e) => {
    (e.target.value.length) ?
      this.setState({
        workTimeOut: parseInt(e.target.value, 10),
      }) :
      this.setState({ workTimeOut: 0 });
  }

  handleAutoAccept = (e) => {
    (e.target.value === 'true') ?
      this.setState({ autoAccept: true }) :
      this.setState({ autoAccept: false });
  }

  handleClose = () => {
    !this.state.isLoading &&
      this.props.closeModal();
  }

  closeModalWithConfirmation = () => {
    !this.state.isLoading &&
      this.props.closeModalWithConfirmation();
  }

  onPhoneChange(event) {
    this.setState({
      deskPhone: event.target.rawValue,
    });
  }

  handleSecurityCheckbox = (index) => {
    const newList = this.state.security;
    const { securityList } = this.state;
    (this.state.security.includes(securityList[index].Id)) ?
      newList.splice(newList.indexOf(securityList[index].Id), 1) :
      newList.splice(0, 0, securityList[index].Id);
    this.setState({
      security: newList,
    });
  }

  getTitle = () => {
    return this.state.isLoading ?
      <div className="container">
        <LoadingCoffeeCup className="centered" title="Loading" />
      </div> :
      <Animator animation="fade">
        <div className="row">
          <div className="col">
            <h4>
              <small>Edit User:</small>
              <Rule className="pt3" />
            </h4>
            <Modal.Title>
              {this.state.agent && this.state.agent.User.Username}
            </Modal.Title>
          </div>
        </div>
      </Animator>;
  }

  getSaveButton = () => {
    if (!this.state.firstName.length || !this.state.lastName.length ||
      !this.state.security.length ||
      ((this.state.agent.User.PhoneConfig.DeskPhoneNumber.length) && (this.state.deskPhone.length < 10)) ||
      (this.state.workTimeOut < 0) || (this.state.workTimeOut > 500)) {
      return (
        <Button
          disabled
          type="submit"
          className="mb-3 submitCloseButtons" >
          Save
        </Button>);
    } else if (this.state.saveInProgress) {
      return (
        <Button
          disabled
          type="submit"
          className="mb-3 submitCloseButtons" >
          Saving . . .
        </Button>);
    } else {
      return (
        <Button
          type="submit"
          className="mb-3 submitCloseButtons"
          onClick={this.handleUpdateUser}
          name="Close Button">
          Save
        </Button>);
    }
  }

  getCloseButton = () => {
    return this.state.saveInProgress ?
      <Button
        disabled
        variant="outline-secondary"
        className="mb-3 submitCloseButtons" >
        Cancel
      </Button> :
      <Button
        variant="outline-secondary"
        onClick={this.handleClose}
        className="mb-3 submitCloseButtons"
        name="Close Button" >
        Cancel
      </Button>;
  }

  renderPhoneConfig = () => {
    const { PhoneConfig } = this.state.agent.User;
    return (
      <div>
        <div className="row">
          <Form.Group controlId="phoneType" className="col-auto formGroup">
            <Form.Label className="formLabel">
              Phone Type
            </Form.Label>
            <div className="selectFormSizeControl">
              <select
                className="form-control"
                as="select"
                defaultValue={PhoneConfig.PhoneType}
                onChange={this.handleClick}
                id="phoneType"
                name="Soft or desk">
                <option value="DESK_PHONE">Desk Phone</option>
                <option value="SOFT_PHONE">Soft Phone</option>
              </select>
            </div>
          </Form.Group>
        </div>
        <div className="row">
          {
            (this.state.phoneType === 'DESK_PHONE')
              ? <Form.Group className="formGroup col-7 mr-3">
                <Form.Label className="formLabel">
                  Deskphone Number
                </Form.Label>
                <div className="row">
                  <div className="col-1 mt-2">
                    <img width="24px" src="images/us-flag-icon.png"></img>
                  </div>
                  <div className="col-1 formLabel mr-2">
                    +1
                  </div>
                  <Cleave
                    className="form-control col"
                    value={this.state.deskPhone}
                    options={{ phone: true, phoneRegionCode: 'US' }}
                    onChange={this.onPhoneChange}
                  />
                  {
                    (PhoneConfig.DeskPhoneNumber.length
                      && !this.state.deskPhone.length) ?
                      <span className="ml-5 pl-4 mb-1 currentEmergencyMessage">
                        Cannot delete existing deskphone number.
                      </span>
                      : null
                  }
                  {
                    (PhoneConfig.DeskPhoneNumber.length
                      && ((this.state.deskPhone.length < 10)) || (this.state.deskPhone.length > 10)) ?
                      <span className="ml-5 pl-4 mb-1 currentEmergencyMessage">
                        Required format: xxx xxx xxxx
                      </span>
                      : null
                  }
                  {
                    (this.state.errorMessage && !this.state.saveInProgress) ?
                      <span className="ml-5 pl-4 mb-1 currentEmergencyMessage">
                        {this.state.errorMessage}
                      </span>
                      : null
                  }
                </div>
              </Form.Group>
              : null
          }
          {
            (this.state.phoneType === 'SOFT_PHONE') ?
              <Form.Group controlId="autoAccept" className="col-4 formGroup mr-3">
                <Form.Label className="formLabel">
                  Auto Accept Calls
                </Form.Label>
                <div className="selectFormSizeControl">
                  <select
                    className="form-control"
                    as="select"
                    defaultValue={PhoneConfig.AutoAccept}
                    onChange={this.handleAutoAccept}
                    name="Auto Answer Toggle">
                    <option value="true">On</option>
                    <option value="false">Off</option>
                  </select>
                </div>
              </Form.Group> : null
          }
          <Form.Group controlId="acwTimeout" className="col-4 formGroup">
            <Form.Label className="formLabel">
              ACW Time Limit
            </Form.Label>
            <Form.Control
              required
              type="number"
              min="0"
              max="500"
              disabled={!this.state.deskPhone && this.state.phoneType === 'DESK_PHONE'}
              defaultValue={PhoneConfig.AfterContactWorkTimeLimit}
              onChange={this.handleTimeout}
              name="After Work Timeout"
            />
            {
              ((this.state.workTimeOut < 0) || (this.state.workTimeOut > 500)) &&
              <span className="mb-1 currentEmergencyMessage">
                Input must be between 0-500
              </span>
            }
            {
              (!this.state.deskPhone && this.state.phoneType === 'DESK_PHONE') &&
              <span className="mb-1 upcomingEmergencyMessage">
                <p>Time Limit cannot be modified without a Desk Phone #</p>
              </span>
            }
          </Form.Group>
        </div>
      </div>
    );
  }

  renderRoutingSelect = () => {
    return (
      <div className="row">
        <Form.Group controlId="routingProfile" className="col formGroup">
          <Form.Label className="formLabel">
            Routing Profile
          </Form.Label>
          <div className="selectFormSizeControl">
            <select
              required
              className="form-control"
              as="select"
              id="routing"
              name="Routing Profile"
              defaultValue={this.state.routing}
              onChange={this.handleClick}>
              {this.state.routingList.map((value, index) => {
                return <option key={index} value={value.Id}>{value.Name}</option>;
              })}
            </select>
          </div>
        </Form.Group>
      </div>
    );
  }

  renderSecuritySelect = () => {
    const { security } = this.state;
    const { securityList } = this.state;
    return (
      <div>
        <Form.Label className="formLabel">
          Security Profile
        </Form.Label>
        <div className="row mt-1 mb-2 pr-1">
          {
            securityList.map((value, index) => {
              return this.state.saveInProgress ?
                <div className="col-4 mb-3" key={index}>
                  <Form.Check
                    disabled
                    checked={security.includes(value.Id)}
                    label={securityList[index].Name} />
                </div> :
                <div className="col-4 mb-3" key={index}>
                  <Form.Check
                    onChange={this.handleSecurityCheckbox.bind(
                      value.Id, index,
                    )}
                    checked={security.includes(value.Id)}
                    label={securityList[index].Name} />
                </div>;
            })
          }
          {
            !security.length &&
            <span className="ml-3 currentEmergencyMessage">
              At least one security profile must be selected.
            </span>
          }
        </div>
      </div>
    );
  }

  renderUser = () => {
    const { User } = this.state.agent;
    return (
      <div>
        <Form.Group className="formGroup">
          <Form.Label className="formLabel">
            First Name
          </Form.Label>
          <Form.Control
            required
            type="text"
            defaultValue={User.IdentityInfo.FirstName}
            onChange={this.handleClick}
            id="firstName"
            name="First Name" />
          {
            !this.state.firstName.length &&
            (<span className="currentEmergencyMessage">
              Required field. Cannot be left blank.
            </span>)
          }
        </Form.Group>
        <Form.Group className="formGroup">
          <Form.Label className="formLabel">
            Last Name
          </Form.Label>
          <Form.Control
            required
            type="text"
            defaultValue={User.IdentityInfo.LastName}
            onChange={this.handleClick}
            id="lastName"
            name="Last Name" />
          {
            !this.state.lastName.length &&
            (<span className="currentEmergencyMessage">
              Required field. Cannot be left blank.
            </span>)
          }
        </Form.Group>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Modal show={this.props.modalStatus} onHide={this.handleClose}>
          <Modal.Header closeButton>
            {this.getTitle()}
          </Modal.Header>
          <Animator animation="fade">
            {
              (this.state.agent && this.state.securityList
                && this.state.routingList && !this.state.isLoading) &&
              <Modal.Body>
                {this.renderUser()}
                {this.renderPhoneConfig()}
                {this.renderRoutingSelect()}
                {this.renderSecuritySelect()}
                <Form className="container">
                  {this.getSaveButton()}
                  {this.getCloseButton()}
                </Form>
              </Modal.Body>
            }
          </Animator>
        </Modal>
      </div >
    );
  }
}
