import Settings from './Settings.js';
import Logger from './Logger.js';
import apigClientFactory from 'aws-api-gateway-client';

export default class Description  {
  static getDescriptionMessage = async (messageType, idJwtToken) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/description/${messageType}`;
    const method = 'GET';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {};

    const lob = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    Logger.verbose(JSON.stringify(lob));
    return lob.data.descriptionMessage;
  };

  static updateDescriptionMessage = async (
    messageType,
    description,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/description/${messageType}`;
    const method = 'POST';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      description,
    };

    try {
      const updateDescription = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );

      Logger.verbose(JSON.stringify(
        `Message type updated`, updateDescription));
      return true;
    } catch (err) {
      Logger.error('Exception Update Description message: ' +
        JSON.stringify(err));
      return false;
    }
  }
}
