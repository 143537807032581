import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { Auth } from 'aws-amplify';

import Agents from '../modules/Agents.js';

import { CSVLink, CSVDownload } from 'react-csv';

export class ExportUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filename: '',
      exporting: false,
      isLoading: false,
      describeUserList: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  getSaveButton = () => {
    if (!this.state.filename.length) {
      return (
        <Button
          disabled
          type="button"
          className="mb-3 submitCloseButtons" >
          Export
        </Button>);
    } else {
      return (
        <CSVLink
          data={this.props.describeUserList.DescribeUsersList}
          filename={this.state.filename}
          onClick={this.handleClose}
          id="csv button"
          className="btn btn-primary submitCloseButtons"
        >
          Export
        </CSVLink>
      );

    }
  }

  getCloseButton = () => {
    return this.state.exporting
      ? <Button
        disabled
        variant="outline-secondary"
        className="mb-3 submitCloseButtons" >
        Cancel
      </Button>
      : <Button
        variant="outline-secondary"
        onClick={this.handleClose}
        className="mb-3 submitCloseButtons"
        name="Close Button" >
        Cancel
      </Button>;
  }

  handleClose = () => {
    this.setState({
      filename: '',
    });
    !this.state.isLoading &&
      this.props.closeModal();
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    if (value === '') {
      this.setState({
        [name]: '',
      });
    } else {
      this.setState({
        [name]: value + '.csv',
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.filename.length) {
      document.getElementById('csv button').click();
    }
  }

  download = () => {
    return (
      <div>
        <CSVDownload
          data={this.props.describeUserList.DescribeUsersList}
          filename={this.state.filename}
          target="_blank"
        />
      this.handleClose();
      </div>
    );
  }

  render() {
    return (
      <Modal show={this.props.modalStatus} onHide={this.handleClose}>
        <Modal.Header closeButton>
          Export Users
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group className="formGroup">
              <Form.Label className="formLabel">
                File Name
              </Form.Label>
              <Form.Control type="text" name="filename" onChange={this.handleChange} />.csv
            </Form.Group>
            {this.getSaveButton()}
            {this.getCloseButton()}
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ExportUsers;
