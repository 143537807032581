import React, { Component } from 'react';

import EmergencyMesssage from './EmergencyMessage';
import PlannedMessages from './PlannedMessages';

export default class SituationalMessages extends Component {
  render() {
    return (
      <div>
        <EmergencyMesssage
          currentLineOfBusiness={this.props.currentLineOfBusiness}
          userName={this.props.userName}
          permissions={this.props.permissions}
        />
        <PlannedMessages
          currentLineOfBusiness={this.props.currentLineOfBusiness}
          userName={this.props.userName}
          permissions={this.props.permissions}
        />
      </div>
    );
  }
}
