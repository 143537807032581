import React, { Component } from 'react';
import { Modal, Form, Button, Table } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Logger from '../modules/Logger.js';
import Agents from '../modules/Agents';

import LoadingCoffeeCup from
  '@starbucks/pattern-library/lib/components/loading-coffee-cup';
import Rule from '@starbucks/pattern-library/lib/components/rule';
import Animator from '@starbucks/pattern-library/lib/components/animator';

export default class BulkEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveInProgress: false,
      isLoading: true,
      routing: '',
      routingList: [],
      routingFromApi: [],
      security: [],
      securityList: [],
      securityFromApi: [],
      workTimeOut: null,
      autoAccept: null,
      errorMessage: '',
      saveComplete: false,
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData = async () => {
    this.setState({
      isLoading: true,
    });
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    try {
      if (idJwtToken) {
        const securityFromApi =
          await Agents.getSecurityList(
          );
        const routingFromApi =
          await Agents.getRoutingList(
          );
        this.setState({
          securityFromApi,
          routingFromApi,
        });
      } else {
        Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
        this.props.closeModal();
      }
      this.filterSecurityList();
      this.filterRoutingList();
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      console.log('An error has occurred while fetching user data', e);
      this.props.closeModal();
    }
  }

  filterSecurityList = () => {
    const listFromApi = this.state.securityFromApi.SecurityProfileSummaryList;
    const securityListFiltered = listFromApi.filter(list =>
      (this.props.permissions.isGlobalAdmin &&
        (list.Name.includes('Admin') || list.Name.includes('QualityAnalyst')
          || list.Name.includes('CallCenterManager')))
      || list.Name.includes(this.props.currentLineOfBusiness)
    );
    this.setState({
      securityList: securityListFiltered,
    });
  }

  filterRoutingList = () => {
    const listFromApi = this.state.routingFromApi.RoutingProfileSummaryList;
    const routingListFiltered = listFromApi.filter((list) => {
      if ((this.state.agent && list.Name.includes('Basic'))
        || list.Name.includes(this.props.currentLineOfBusiness)
      ) {
        return true;
      }
    });
    this.setState({
      routingList: routingListFiltered,
    });
  }

  filterErrorMessage = (error) => {
    const errorId = error[0].slice(-36);
    const errorIndex = this.props.userEditList.indexOf(errorId);
    this.setState({
      errorMessage: `${error[0].slice(0, -36)} ${this.props.userEditNameList[errorIndex]}`,
      saveInProgress: false,
    });
  }

  saveCycle = async () => {
    this.setState({
      saveInProgress: true,
    });
    await this.props.userEditList.forEach(async (value, index, array) => {
      try {
        const isUpdateSuccess = await this.handleUpdateUser(value);
        if (isUpdateSuccess && (array.length - 1 - index === 0)) {
          !this.state.errorMessage ?
            this.handleCloseWithConfirmation() :
            this.setState({ saveInProgress: false });
        }
      } catch (e) {
        console.error('an exception has occurred', e);
        this.setState({
          errorMessage: 'A server error occured.',
          saveInProgress: false,
        });
      }
    });
  }

  handleUpdateUser = async (amazonId) => {
    const isUpdateRoutingSuccess = await this
      .updateUserRoutingProfile(
        amazonId,
        this.state.routing,
      );
    const isUpdateAutoAcceptSuccess = await this
      .updateUserAutoAccept(
        amazonId,
        'SOFT_PHONE',
        this.state.autoAccept,
      );
    const isUpdateTimeOutSuccess = await this
      .updateUserTimeOut(
        amazonId,
        'SOFT_PHONE',
        this.state.workTimeOut,
      );
    const isUpdateSecuritySuccess = await this
      .updateUserSecurityProfile(
        amazonId,
        this.state.security,
      );
    return (isUpdateRoutingSuccess && isUpdateAutoAcceptSuccess &&
      isUpdateTimeOutSuccess && isUpdateSecuritySuccess);
  }

  updateUserAutoAccept = async (
    amazonId,
    softOrDesk,
    autoAccept,
  ) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    if (idJwtToken && (this.state.autoAccept !== null)) {
      const isUpdateSuccess = await Agents.updateBulkAutoAccept(
        amazonId,
        softOrDesk,
        autoAccept,
        idJwtToken,
      );
      if (Object.values(isUpdateSuccess).includes('Success')) {
        return isUpdateSuccess;
      } else if ((Object.keys(isUpdateSuccess).includes('ClientError'))) {
        this.filterErrorMessage(Object.values(isUpdateSuccess));
        return false;
      }
    } else {
      return true;
    }
  }

  updateUserTimeOut = async (
    amazonId,
    softOrDesk,
    workTimeOut,
  ) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    if (idJwtToken && (this.state.workTimeOut !== null)) {
      const isUpdateSuccess = await Agents.updateBulkTimeOut(
        amazonId,
        softOrDesk,
        workTimeOut,
        idJwtToken,
      );
      if (Object.values(isUpdateSuccess).includes('Success')) {
        return isUpdateSuccess;
      } else if ((Object.keys(isUpdateSuccess).includes('ClientError'))) {
        this.filterErrorMessage(Object.values(isUpdateSuccess));
        return false;
      }
    } else {
      return true;
    }
  }

  updateUserRoutingProfile = async (amazonId, routing) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    if (idJwtToken &&
      (this.state.routing !== 'none') && (this.state.routing.length)) {
      const isUpdateSuccess = await Agents.updateUserRoutingProfile(
        amazonId,
        routing,
        idJwtToken,
      );
      if ((Object.keys(isUpdateSuccess).includes('ClientError'))) {
        this.filterErrorMessage(Object.values(isUpdateSuccess));
        return false;
      } else {
        return isUpdateSuccess;
      }
    } else {
      return true;
    }
  }

  updateUserSecurityProfile = async (amazonId, security) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    if (idJwtToken && this.state.security.length) {
      const isUpdateSuccess = await Agents.updateUserSecurityProfile(
        amazonId,
        security,
        idJwtToken,
      );
      if ((Object.keys(isUpdateSuccess).includes('ClientError'))) {
        this.filterErrorMessage(Object.values(isUpdateSuccess));
        return false;
      } else {
        return isUpdateSuccess;
      }
    } else {
      return true;
    }
  }

  handleClick = (e) => {
    const { value } = e.target;
    this.setState({
      routing: value,
    });
  }

  handleTimeout = (e) => {
    (e.target.value === '') ?
      this.setState({
        workTimeOut: null,
      }) :
      (e.target.value >= 0) ?
        this.setState({
          workTimeOut: parseInt(e.target.value, 10),
        }) :
        this.setState({
          workTimeOut: 0,
        });
  }

  handleAutoAccept = (e) => {
    (e.target.value === 'true') ? this.setState({ autoAccept: true }) :
      (e.target.value === 'false') ? this.setState({ autoAccept: false }) :
        this.setState({ autoAccept: null });
  }

  handleClose = () => {
    (!this.state.isLoading || !this.state.saveInProgress) &&
      this.props.closeModal();
  }

  handleCloseWithConfirmation = () => {
    (!this.state.isLoading || !this.state.saveInProgress) &&
      this.props.closeModalWithConfirmation();
  }

  handleSecurityCheckbox = (index) => {
    const newList = this.state.security;
    const { securityList } = this.state;
    (this.state.security.includes(securityList[index].Id)) ?
      newList.splice(newList.indexOf(securityList[index].Id), 1) :
      newList.splice(0, 0, securityList[index].Id);
    this.setState({
      security: newList,
    });
  }

  errorMessage = () => {
    return this.state.errorMessage ?
      <div className="col-auto currentEmergencyMessage">
        {this.state.errorMessage}
      </div> :
      null;
  }

  getTitle = () => {
    return this.state.isLoading ?
      <div className="container">
        <LoadingCoffeeCup className="centered" title="Loading" />
      </div> :
      <Animator animation="fade">
        <div className="row">
          <div className="col">
            <h4>
              <small>Edit Users:</small>
              <Rule className="pt3" />
            </h4>
            <Table striped className="mt-3 pr-3 bulkEdit">
              <tbody className="bulkEdit">
                {
                  this.props.userEditNameList.map((value, index) => {
                    return <tr className="bulkEdit" key={index}>{value}</tr>;
                  })
                }
              </tbody>
            </Table>
          </div>
        </div>
      </Animator>;
  }

  getSaveButton = () => {
    if (!this.state.security.length && !this.state.routing.length &&
      this.state.autoAccept === null && this.state.workTimeOut === null) {
      return (
        <Button
          disabled
          type="submit"
          className="mb-3 submitCloseButtons" >
          Save
        </Button>);
    } else if (this.state.saveInProgress) {
      return (
        <Button
          disabled
          type="submit"
          className="mb-3 submitCloseButtons" >
          Saving . . .
        </Button>);
    } else if (this.state.errorMessage.length) {
      return (
        <Button
          disabled
          type="submit"
          className="mb-3 submitCloseButtons" >
          Error
        </Button>);
    } else {
      return (
        <Button
          type="submit"
          className="mb-3 submitCloseButtons"
          onClick={this.saveCycle}
          name="Close Button">
          Save
        </Button>);
    }
  }

  getCloseButton = () => {
    return (
      <Button
        variant="outline-secondary"
        onClick={this.handleClose}
        className="mb-3 submitCloseButtons"
        name="Close Button" >
        Cancel
      </Button>);
  }

  renderPhoneConfig = () => {
    return (
      <div>
        <div className="row">
          <Form.Label className="col mb-3">
            <b>Phone type: </b>Soft Phone
          </Form.Label>
        </div>
        <div className="row">
          <Form.Group controlId="acwTimeout" className="col formGroup mr-4">
            <Form.Label className="formLabel">
              ACW Time Limit
            </Form.Label>
            <Form.Control
              required
              type="number"
              min="0"
              defaultValue=""
              onChange={this.handleTimeout}
              name="After Work Timeout"
            />
          </Form.Group>
          <Form.Group controlId="autoAccept" className="col formGroup">
            <Form.Label className="formLabel">
              Auto Accept Calls
            </Form.Label>
            <div className="selectFormSizeControl">
              <select
                className="form-control"
                as="select"
                defaultValue=""
                onChange={this.handleAutoAccept}
                name="Auto Answer Toggle">
                <option value=""></option>
                <option value="true">On</option>
                <option value="false">Off</option>
              </select>
            </div>
          </Form.Group>
        </div>
      </div>
    );
  }

  renderRoutingSelect = () => {
    return (
      <div className="row mt-2">
        <Form.Group controlId="routingProfile" className="col formGroup mr-2">
          <Form.Label className="formLabel">
            Routing Profile
          </Form.Label>
          <div className="selectFormSizeControl">
            <select
              required
              className="form-control"
              as="select"
              id="routing"
              name="Routing Profile"
              defaultValue=""
              onChange={this.handleClick}>
              <option key="none" value=""></option>
              {this.state.routingList.map((value, index) => {
                return <option key={index} value={value.Id}>{value.Name}
                </option>;
              })}
            </select>
          </div>
        </Form.Group>
      </div>
    );
  }

  renderSecuritySelect = () => {
    const { security } = this.state;
    const { securityList } = this.state;
    return (
      <div>
        <Form.Label className="formLabel">
          Security Profile
        </Form.Label>
        <div className="row mt-1 mb-2 pr-1">
          {
            securityList.map((value, index) => {
              return this.state.saveInProgress ?
                <div className="col-4 mb-3" key={index}>
                  <Form.Check
                    disabled
                    checked={security.includes(value.Id)}
                    label={securityList[index].Name} />
                </div> :
                <div className="col-4 mb-3" key={index}>
                  <Form.Check
                    onChange={this.handleSecurityCheckbox.bind(
                      value.Id, index,
                    )}
                    checked={security.includes(value.Id)}
                    label={securityList[index].Name} />
                </div>;
            })
          }
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Animator animation="fade">
          <Modal show={this.props.modalStatus} onHide={this.handleClose}>
            <Modal.Header closeButton>
              {this.getTitle()}
            </Modal.Header>
            {
              (this.state.securityList && this.state.routingList &&
                !this.state.isLoading) &&
              <Modal.Body>
                {this.renderPhoneConfig()}
                {this.renderRoutingSelect()}
                {this.renderSecuritySelect()}
                <Form className="container">
                  {this.errorMessage()}
                  {this.getSaveButton()}
                  {this.getCloseButton()}
                </Form>
              </Modal.Body>
            }

          </Modal>
        </Animator>
      </div >
    );
  }
}
