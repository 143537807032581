import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Button } from 'react-bootstrap';
import Logger from '../modules/Logger.js';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import LoadingCoffeeCup from
  '@starbucks/pattern-library/lib/components/loading-coffee-cup';

import Agents from '../modules/Agents';
import Enums from '../modules/Enums';
import AgentsTableModal from './AgentsTableModal';
import BulkEditModal from './BulkEditModal';
import ExportUsers from './ExportUsers.js';
import ImportUsers from './ImportUsers.js';
import CreateUser from './CreateUser.js';
import ConfirmationModal from './ConfirmationModal';
import {
  pagination, sortByColumn, getDataForCurrentPage,
  resetPaginationOptions, filterByValue,
} from '../modules/TableTools';

export default class AgentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agents: [],
      agentsForCurrentPage: [],
      updateInProgress: false,
      currentAgentUpdatingId: '',
      isLoading: false,
      showEditModal: false,
      showBulkEditModal: false,
      userEditList: [],
      userEditNameList: [],
      showExportModal: false,
      showConfirmationModal: false,
      showCreateModal: false,
      errorMessage: '',
      describeUserList: {},
      pagination: pagination,
      sortOrder: 'asc',
      sortField: 'Username',
      searchText: '',
    };
  }

  componentWillUnmount() {
    let pagination = resetPaginationOptions();
    this.setState({
      pagination: pagination,
      sortOrder: 'asc',
      sortField: 'Username',
    });
  }

  componentDidMount() {
    this.loadAgents();
  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps !== undefined
      && prevProps.currentLineOfBusiness !== this.props.currentLineOfBusiness
    ) {
      this.loadAgents();
    }
  }

  handleSubmit = async () => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    try {
      if (idJwtToken) {
        const describeUserList = await Agents
          .describeUserList(this.state.userEditList, idJwtToken);
        if (describeUserList.DescribeUsersList) {
          this.setState({
            describeUserList,
          });
          this.openExportModal();
        } else {
          this.setState({
            errorMessage: describeUserList.Message,
            showExportModal: false,
            describeUserList: {},
          });
        }
      }
    } catch (error) {
      console.error('there was an error getting data', error);
    }
  };

  loadAgents = async () => {
    if (this.props.currentLineOfBusiness === undefined) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    if (idJwtToken) {
      const agents =
        await Agents.getAgents(
          Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness],
          idJwtToken,
        );

      if (agents && agents.length) {
        pagination.totalSize = agents.length;
        this.setState({
          agents,
          isLoading: false,
          pagination: pagination,
        });

        this.sortAgents(this.state.sortField, this.state.sortOrder);
        this.setAgentsForCurrentPage();

      } else {
        this.setState({
          isLoading: false,
        });
      }
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  }

  setAgentsForCurrentPage = () => {
    this.setState({
      agentsForCurrentPage: getDataForCurrentPage(this.state.agents, this.state.pagination),
    });
  }

  sortAgents = (sortField, sortOrder) => {
    this.state.agents = sortByColumn(this.state.agents, sortField, sortOrder);
    this.setState({
      sortOrder: sortOrder,
      sortField: sortField,
    });
  }

  filterAgents = (searchText) => {
    if (searchText !== this.state.searchText) {
      pagination.page = 1;
      this.setState({
        pagination: pagination,
        searchText: searchText,
        agentsForCurrentPage: filterByValue(this.state.agents, searchText, ['Username', 'FirstName', 'LastName', 'RoutingProfileName', 'SecurityProfileName', 'ACWTimeout', 'PhoneType']),
      });
    }
  }

  onTableChange = (type, newState) => {
    this.sortAgents(newState.sortField, newState.sortOrder);
    pagination.page = newState.page;
    pagination.sizePerPage = newState.sizePerPage;

    this.setState({
      pagination: pagination,
    });

    this.setAgentsForCurrentPage();
    this.filterAgents(newState.searchText);
  }

  updateAgent = async (amazonId, autoAccept) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    if (idJwtToken) {
      try {
        const isUpdateSuccess = await Agents
          .updateAgentAutoAcceptCall(
            amazonId,
            autoAccept,
            idJwtToken,
          );
        return isUpdateSuccess;
      } catch (err) {
        return false;
      }
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
      return null;
    }
  }

  openEditModal = (id) => {
    this.setState({
      showEditModal: true,
      currentAgentUpdatingId: id,
    });
  };

  openBulkEditModal = () => {
    this.setState({
      showBulkEditModal: true,
    });
  };

  openExportModal = () => {
    this.setState({
      showExportModal: true,
    });
  };

  openImportModal = () => {
    this.setState({
      showImportModal: true,
    });
  };

  openCreateModal = () => {
    this.setState({
      showCreateModal: true,
    });
  };

  closeModal = () => {
    this.loadAgents();
    this.setState({
      showEditModal: false,
      showBulkEditModal: false,
      showExportModal: false,
      showImportModal: false,
      showCreateModal: false,
      showConfirmationModal: false,
      currentAgentUpdatingId: '',
      errorMessage: '',
    });
  }

  closeModalWithConfirmation = () => {
    this.loadAgents();
    this.setState({
      showEditModal: false,
      showBulkEditModal: false,
      showExportModal: false,
      showImportModal: false,
      showCreateModal: false,
      showConfirmationModal: true,
      currentAgentUpdatingId: '',
      errorMessage: '',
    });
  }

  checkUsers = () => {
    if (!this.state.userEditList.length) {
      this.setState({
        errorMessage: 'Please select at least one user to export',
      });
    } else {
      this.handleSubmit();
    }
  };

  render() {
    const { SearchBar } = Search;
    const editModal = this.state.showEditModal
      ? <AgentsTableModal
        amazonId={this.state.currentAgentUpdatingId}
        modalStatus={this.state.showEditModal}
        closeModal={this.closeModal}
        closeModalWithConfirmation={this.closeModalWithConfirmation}
        currentLineOfBusiness={this.props.currentLineOfBusiness}
        permissions={this.props.permissions}
      />
      : null;
    const bulkEditModal = this.state.showBulkEditModal
      ? <BulkEditModal
        modalStatus={this.state.showBulkEditModal}
        closeModal={this.closeModal}
        closeModalWithConfirmation={this.closeModalWithConfirmation}
        currentLineOfBusiness={this.props.currentLineOfBusiness}
        permissions={this.props.permissions}
        userEditList={this.state.userEditList}
        userEditNameList={this.state.userEditNameList}
      />
      : null;
    const exportModal = this.state.showExportModal
      ? <ExportUsers
        bulkDownloadUsers={this.state.userEditList}
        closeModal={this.closeModal}
        modalStatus={this.state.showExportModal}
        describeUserList={this.state.describeUserList}
      />
      : null;
    const importModal = this.state.showImportModal
      ? <ImportUsers
        closeModal={this.closeModal}
        closeModalWithConfirmation={this.closeModalWithConfirmation}
        modalStatus={this.state.showImportModal}
      />
      : null;
    const confirmationModal = this.state.showConfirmationModal
      ? <ConfirmationModal
        closeModal={this.closeModal}
        modalStatus={this.state.showConfirmationModal}
      />
      : null;

    const createModal = this.state.showCreateModal
      ? <CreateUser
        modalStatus={this.state.showCreateModal}
        closeModal={this.closeModal}
        closeModalWithConfirmation={this.closeModalWithConfirmation}
        currentLineOfBusiness={this.props.currentLineOfBusiness}
      />
      : null;

    const renderAutoAccept = (cellContent, row) => {
      return (
        <div>
          {cellContent === 'True' ? 'On' : 'Off'}
        </div>
      );
    };

    const columns = [
      {
        dataField: 'Username', text: 'User Name', sort: true, editable: false,
        headerStyle: () => {
          return { width: '260px', textAlign: 'center' };
        },
      },
      {
        dataField: 'FirstName', text: 'First Name', sort: true, editable: false,
        headerStyle: () => {
          return { width: '140px', textAlign: 'center' };
        },
      },
      {
        dataField: 'LastName', text: 'Last Name', sort: true, editable: false,
        headerStyle: () => {
          return { width: '150px', textAlign: 'center' };
        },
      },
      {
        dataField: 'RoutingProfileName', text: 'Routing Profile',
        sort: true, editable: false,
        headerStyle: () => {
          return { width: '160px', textAlign: 'center' };
        },
      },
      {
        dataField: 'SecurityProfileName', text: 'Security Profile',
        sort: true, editable: false,
        headerStyle: () => {
          return { width: '170px', textAlign: 'center' };
        },
      },
      {
        dataField: 'ACWTimeout', text: 'ACW Timeout', type: 'number',
        sort: true, editable: false,
        headerStyle: () => {
          return { width: '100px', textAlign: 'center' };
        },
      },
      {
        dataField: 'AutoAcceptCall', text: 'Auto Accept',
        sort: true, editable: false,
        headerStyle: () => {
          return { width: '100px', textAlign: 'center' };
        },
        formatter: renderAutoAccept,
      },
      {
        dataField: 'PhoneType', text: 'Phone Type', sort: true, editable: false,
        headerStyle: () => {
          return { width: '110px', textAlign: 'center' };
        },
      },
    ];

    const selectRow = {
      mode: 'checkbox',
      onSelectAll: (isSelect, rows) => {
        const { userEditList, userEditNameList } = this.state;
        if (!isSelect) {
          this.setState({
            userEditList: [],
            userEditNameList: [],
          });
        } else {
          let newList = userEditList;
          let newNameList = userEditNameList;
          for (let i = 0; i < rows.length; i++) {
            newNameList.splice(0, 0, rows[i].Username);
            newList.splice(0, 0, rows[i].AmazonConnectId);
          }
          this.setState({
            userEditList: newList,
            userEditNameList: newNameList,
          });
        }
      },
      onSelect: (row, isSelect) => {
        const { userEditList, userEditNameList } = this.state;
        const newId = row.AmazonConnectId;
        const newList = userEditList;
        const newName = row.Username;
        const newNameList = userEditNameList;
        (userEditList.includes(newId)) ?
          newList.splice(newList.indexOf(newId), 1) :
          newList.splice(0, 0, newId);
        (newNameList.includes(newName)) ?
          newNameList.splice(newNameList.indexOf(newName), 1) :
          newNameList.splice(0, 0, newName);
        this.setState({
          userEditList: newList,
          userEditNameList: newNameList,
        });
      },
    };

    return (
      <div>
        {
          this.state.errorMessage && (
            <p className="m-2 text-center">{this.state.errorMessage}</p>
          )
        }
        <div className="planned-messages agentsTable">
          {
            !this.state.agents.length ?
              null :
              <Button
                className="agentTableButton"
                onClick={this.openImportModal}>
                Import
              </Button>
          }
          {
            !this.state.agents.length ?
              null :
              <Button
                className="agentTableButton"
                onClick={this.openCreateModal}>
                Add New User
              </Button>
          }
          {
            this.state.userEditList.length ?
              <Button
                className="agentTableButton"
                onClick={this.state.userEditList.length === 1 ?
                  () => this.openEditModal(this.state.userEditList[0]) :
                  this.openBulkEditModal}>
                Edit ({this.state.userEditList.length}) Users
              </Button> : null
          }
          {
            this.state.userEditList.length ?
              <Button
                className="agentTableButton"
                onClick={this.checkUsers}>
                Export
              </Button> : null
          }
          <div className="mx-2">
            {
              (!this.state.updateInProgress) ?
                null :
                <div className="agentUpdateNoClick">
                </div>
            }
            <div className="planned-messages">
              <div>
                {
                  !this.state.agents.length ?
                    <div className="loadingContainer">
                      <LoadingCoffeeCup title="Loading" />
                    </div> :

                    <ToolkitProvider
                      bootstrap4
                      keyField="AmazonConnectId"
                      data={this.state.agentsForCurrentPage}
                      columns={columns}
                      wrapperClasses="table-responsive"
                      rowClasses="text-nowrap"
                      search
                    >
                      {
                        props => (
                          <div>
                            <SearchBar
                              className="tableSearch"
                              {...props.searchProps} />
                            <hr />
                            <BootstrapTable
                              remote
                              bootstrap4
                              onTableChange={this.onTableChange}
                              pagination={paginationFactory(this.state.pagination)}
                              striped={true}
                              selectRow={selectRow}
                              rowClasses="text-nowrap"
                              cellEdit={cellEditFactory(
                                { mode: 'click', blurToSave: true })}
                              {...props.baseProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                }
              </div>
              {importModal}
              {editModal}
              {bulkEditModal}
              {exportModal}
              {createModal}
              {confirmationModal}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
