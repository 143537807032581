import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";

import HoursOfBusiness from "./HoursOfBusiness";
import GenerateRDSKey from "./GenerateRdsKey";

export default class AdminTabsOfContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Tabs
        id="token"
        activeKey={this.state.key}
        className="nav-tabs adminTabs"
      >
        <Tab eventKey="hoursOfBusiness" title="Hours Of Operation">
          <HoursOfBusiness
            hoursOfBusiness={this.props.hoursOfBusiness}
            editableHours={this.props.editableHours}
            setBusinessHours={this.props.setBusinessHours}
            linesOfBusiness={this.props.linesOfBusiness}
            currentLineOfBusiness={this.props.currentLineOfBusiness}
            changeLineOfBusiness={this.props.changeLineOfBusiness}
            setEditableHours={this.props.setEditableHours}
            resetBusinessHours={this.props.resetBusinessHours}
          />
        </Tab>

        <Tab eventKey="audit_log" title="Audit Log"></Tab>

        <Tab eventKey="token" title="Generate Key">
          <GenerateRDSKey userName={this.props.userName} />
        </Tab>
      </Tabs>
    );
  }
}
