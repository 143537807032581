import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import AdminTabsOfContent from '../components/AdminTabsOfContent';
import LineOfBusiness from '../components/LineOfBusiness';
import Status from '../components/Status';

export default class AdminContainer extends Component {

  render() {
    return (
      <div>
        <Container>
          <div className="titleAndStatusContainer">
            <LineOfBusiness
              linesOfBusiness={this.props.linesOfBusiness}
              currentLineOfBusiness={this.props.currentLineOfBusiness}
              changeLineOfBusiness={this.props.changeLineOfBusiness}
              readOnly={false}
            />
            <Status
              status={this.props.status}
            />
          </div>
          <AdminTabsOfContent
            userName={this.props.userName}
            hoursOfBusiness={this.props.hoursOfBusiness}
            editableHours={this.props.editableHours}
            setBusinessHours={this.props.setBusinessHours}
            linesOfBusiness={this.props.linesOfBusiness}
            currentLineOfBusiness={this.props.currentLineOfBusiness}
            changeLineOfBusiness={this.props.changeLineOfBusiness}
            setEditableHours={this.props.setEditableHours}
            resetBusinessHours={this.props.resetBusinessHours}
            readOnly={false}
            permissions={this.props.permissions}
          />
        </Container>
      </div>
    );
  }
}
