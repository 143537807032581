/* eslint no-console: 0 */

import {LogLevel} from './Enums';

export default class Logger {
  static verbose(message) {
    console.info(`${LogLevel.VERBOSE} ${message}`);
  }

  static warn(message) {
    console.warn(`${LogLevel.WARNING} ${message}`);
  }

  static info(message) {
    console.info(`${LogLevel.INFO} ${message}`);
  }

  static error(message) {
    console.error(`${LogLevel.ERROR} ${message}`);
  }

  static fatal(message) {
    console.trace(`${LogLevel.FATAL} ${message}`);
  }
}
