import { Auth } from 'aws-amplify';
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Agents from '../modules/Agents.js';
import BulkImport from '../modules/BulkImport';
import Logger from '../modules/Logger';

export class ImportUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filename: {},
      importing: false,
      isLoading: false,
      userRows: [],
      errorMessage: '',
      body: '',
    };
  }

  getSaveButton = () => {
    if (!this.state.filename.size) {
      return (
        <Button disabled type="button" className="mb-3 submitCloseButtons">
          Import
        </Button>
      );
    } else if (this.state.userRows.length || this.state.errorMessage.length) {
      return <div></div>;
    } else {
      return (
        <Button
          onClick={this.handleImport}
          id="importButton"
          className="btn btn-primary submitCloseButtons"
        >
          Import
        </Button>
      );
    }
  };

  getCloseButton = () => {
    if (this.state.isLoading) {
      return (
        <Button
          disabled
          variant="outline-secondary"
          className="mb-3 submitCloseButtons"
        >
          Cancel
        </Button>
      );
    } else if (this.state.userRows.length || this.state.errorMessage.length) {
      return (
        <Button
          variant="outline-secondary"
          onClick={this.handleClose}
          className="mb-3 submitCloseButtons"
          name="Close Button"
        >
          Close
        </Button>
      );
    } else {
      return (
        <Button
          variant="outline-secondary"
          onClick={this.handleClose}
          className="mb-3 submitCloseButtons"
          name="Close Button"
        >
          Cancel
        </Button>
      );
    }
  };

  handleClose = () => {
    this.setState({
      filename: {},
      userRows: [],
      errorMessage: '',
      body: '',
    });
    !this.state.isLoading && this.props.closeModal();
  };

  handleImport = async () => {
    this.setState({
      isLoading: true,
    });

    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    try {
      if (idJwtToken) {
        let body = '';
        const obj_csv = {
          size: 0,
          dataFile: [],
        };

        const reader = new FileReader();

        reader.onload = (e) => {
          obj_csv.size = e.total;
          obj_csv.dataFile = e.target.result;
          const lines = obj_csv.dataFile.split(/\r?\n/).filter(line => line.length > 2); // split on return?newline ignoring empty lines

          obj_csv.parsed = lines.map((line) => line.split(','));
          obj_csv.reconstituted = lines.join(',\r\n') + ',\r\n';

          const { isValid, errorMessage } = BulkImport.validate(obj_csv.parsed);
          obj_csv.isValid = isValid;

          if (!isValid) {
            this.setState({ errorMessage });
            Logger.verbose(errorMessage);
          }
        };

        reader.onloadend = async () => {
          if (obj_csv.isValid) {
            Agents.bulkUserImport(obj_csv.reconstituted, idJwtToken)
              .then((userRows) => {
                this.setState({
                  userRows,
                  importing: true,
                });
              })
              .catch((error) => {
                const msg = `${error || 'There was a problem importing users.'
                  }`;
                Logger.verbose(msg);
                this.setState({
                  errorMessage: `There was a problem importing ${this.state.filename}. ${msg}`,
                });
              })
              .finally(() => {
                this.setState({
                  showImportModal: false,
                  importing: true,
                  isLoading: false,
                });
              });
          } else {
            this.setState({
              showImportModal: false,
              importing: true,
              isLoading: false,
            });
          }
        };

        reader.readAsText(this.state.filename, 'utf-8');
      }
    } catch (error) {
      const errorMessage = `There was a problem reading the file: ${error}`;
      Logger.error(
        `There was a problem reading the file: "${JSON.stringify(error || '', null, ' ')}".`
      );
      this.setState({
        errorMessage,
      });
    }
  };

  handleChange = (event) => {
    event.target.files[0].name.endsWith('csv') ?
      this.setState({
        errorMessage: '',
        filename: event.target.files[0],
      })
      : this.setState({
        errorMessage: 'Error: The file upload must be in .csv format.  Please correct and re-upload your file.',
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.filename.length) {
      document.getElementById('csv button').click();
    }
  };

  render() {
    return (
      <Modal show={this.props.modalStatus} onHide={this.handleClose}>
        <Modal.Header closeButton>Import Users</Modal.Header>
        <Modal.Body>
          {!this.state.importing ? (
            <Form onSubmit={this.handleSubmit}>
              <Form.Group className="formGroup">
                <div>
                  <input
                    type="file"
                    onChange={this.handleChange}
                    accept=".csv"
                    onDragOver={false}
                  />
                </div>
              </Form.Group>
            </Form>
          ) : null}

          {this.state.errorMessage ? <p>{this.state.errorMessage}</p> : null}

          {this.state.userRows.length ? (
            <Table striped>
              <thead>
                <tr>
                  <td>Username</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                {this.state.userRows.map((user, index) => (
                  <tr key={user.username}>
                    <td>{user.username}</td>
                    <td>{user.message}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}
          {this.getSaveButton()}
          {this.getCloseButton()}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ImportUsers;
