import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import AgentsTable from './AgentsTable';

export default class AgentsTabsOfContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Tabs
        id="agents"
        activeKey={this.state.key}
        className="nav-tabs">

        <Tab
          eventKey="agents"
          title="Setup">
          <AgentsTable
            currentLineOfBusiness={this.props.currentLineOfBusiness}
            userName={this.props.userName}
            permissions={this.props.permissions} />
        </Tab>
      </Tabs>
    );
  }
}
