import React, { Component } from 'react';
import {
  Container, Table, Col, Collapse, OverlayTrigger,
  Popover,
} from 'react-bootstrap';

import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EditAddPlannedMessages from './EditAddPlannedMessages';
import DescriptionEditModal from './DescriptionEditModal.js';
import EndMessage from './EndMessage';
import Logger from '../modules/Logger.js';
import SituationalMessages from '../modules/SituationalMessages';
import Description from '../modules/Description';
import Enums from '../modules/Enums';
import TimeZone from 'moment-timezone';
import Utils from '../modules/Utils';
import Constants from '../modules/Constants';

TimeZone.tz.setDefault(Constants.TimeZone.PACIFIC_TIME);

export default class PlannedMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plannedMessages: [],
      showModal: false,
      currentMessageSelectedIndex: null,
      showEndPlannedMessage: false,
      currentSectionExpanded: true,
      expiredSectionExpanded: false,
      descriptionModal: false,
      description: null,
      idJwtToken: null,
    };
  }

  componentDidMount() {
    this.loadSituationalMessages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== undefined
      && prevProps.currentLineOfBusiness !== this.props.currentLineOfBusiness
    ) {
      this.loadSituationalMessages();
    }
  }

  async loadSituationalMessages() {
    if (this.props.currentLineOfBusiness === undefined) {
      return;
    }

    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      const plannedMessages =
        await SituationalMessages.getSituationalMessages(
          Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness],
          idJwtToken,
        );

      const description =
        await Description.getDescriptionMessage(
          'plannedMessage',
          idJwtToken,
        );

      this.setState({
        plannedMessages,
        description,
        idJwtToken,
      });
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  }

  deleteLocalPlannedMessage = () => {
    const copyState = this.state.plannedMessages;
    const currentIndex = this.state.currentMessageSelectedIndex;
    const newState = copyState.filter((message, index) =>
      index !== currentIndex
    );

    this.setState({
      plannedMessages: [...newState],
    });
  }

  updateLocalPlannedMessage = (newPlannedMessage) => {
    let copyState = this.state.plannedMessages;
    this.state.currentMessageSelectedIndex === null
      ? copyState = [...copyState, newPlannedMessage]
      : copyState[this.state.currentMessageSelectedIndex] = newPlannedMessage;
    this.setState({
      plannedMessages: [...copyState],
    });
  }

  openModal = (index) => {
    this.setState({
      showModal: true,
      currentMessageSelectedIndex: index,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      currentMessageSelectedIndex: null,
    });
  }

  openEndPlannedMessageModal = (index) => {
    this.setState({
      showEndPlannedMessage: true,
      currentMessageSelectedIndex: index,
    });
  }

  closeEndPlannedMessageModal = () => {
    this.setState({
      showEndPlannedMessage: false,
      currentMessageSelectedIndex: null,
    });
  }

  toggleCurrentSection = () => {
    this.setState({
      currentSectionExpanded: !this.state.currentSectionExpanded,
    });
  }

  toggleExpiredSection = () => {
    this.setState({
      expiredSectionExpanded: !this.state.expiredSectionExpanded,
    });
  }

  openDescriptionEditModal = () => {
    this.setState({
      descriptionModal: true,
    });
  }

  closeDescriptionModal = () => {
    this.setState({
      descriptionModal: false,
    });
  }

  updateLocalDescription = (newDescription) => {
    this.setState({
      description: newDescription,
    });

    return true;
  }

  buildTableRows = (plannedMessages) => {
    const upcomingOrCurrent = [];
    const expired = [];

    const sortedPlannedMessages = Utils.sortMessagesByDate(plannedMessages);
    sortedPlannedMessages.map((message, index) => {

      const messageStatus = Utils.messageStatus(message);
      const startMonthDayYear = TimeZone(Number(message.startDateTime))
        .format(Constants.DateFormat.MONTH_DAY_YEAR);
      const startTime = TimeZone(Number(message.startDateTime))
        .format(Constants.DateFormat.TIME);
      const endMonthDayYear = TimeZone(Number(message.endDateTime))
        .format(Constants.DateFormat.MONTH_DAY_YEAR);
      const endTime = TimeZone(Number(message.endDateTime))
        .format(Constants.DateFormat.TIME);

      const value = message.category === 'RECURRING_MEETING'
        ? <tr key={index}>
          <td className="iconColumn">
            <div className="iconPlaceholder mr-2 ml-2" />
            <FontAwesomeIcon
              className="trash-alt action-icon"
              icon="trash-alt"
              onClick={() => this.openEndPlannedMessageModal(index)}
            />
          </td>
          <td className="descriptionColumn">{message.description}</td>
          <td className="categoryColumn">{message.category}</td>
          <td className="dateColumn">{startMonthDayYear}</td>
          <td className="timeColumn">{startTime}</td>
          <td className="dateColumn">{endMonthDayYear}</td>
          <td className="timeColumn">{endTime}</td>
        </tr>
        : <tr key={index}>
          <td className="iconColumn">
            <FontAwesomeIcon
              className="editIcon action-icon mr-2"
              icon="pen"
              onClick={() => this.openModal(index)}
            />
            <FontAwesomeIcon
              className="trash-alt action-icon"
              icon="trash-alt"
              onClick={() => this.openEndPlannedMessageModal(index)}
            />
          </td>
          <td className="descriptionColumn">{message.description}</td>
          <td className="categoryColumn">{message.category}</td>
          <td className="dateColumn">{startMonthDayYear}</td>
          <td className="timeColumn">{startTime}</td>
          <td className="dateColumn">{endMonthDayYear}</td>
          <td className="timeColumn">{endTime}</td>
        </tr>;

      if (messageStatus === 'UPCOMING' || messageStatus === 'CURRENT') {
        upcomingOrCurrent.push(value);
      } else {
        expired.push(value);
      }
    });

    return {
      upcomingOrCurrent,
      expired,
    };
  }

  editAddPlannedMessages = (index) => {
    return <EditAddPlannedMessages
      modalStatus={this.state.showModal}
      closeModal={this.closeModal}
      situationalMessages={this.state.plannedMessages[index]}
      currentLineOfBusiness={this.props.currentLineOfBusiness}
      updateLocalPlannedMessage={this.updateLocalPlannedMessage}
      userName={this.props.userName}
      allPlannedMessages={this.state.plannedMessages}
      index={index}
    />;
  }

  endPlannedMessage = (index) => {
    const idJwtToken = this.state.idJwtToken;

    if (idJwtToken) {
      const textValues = {
        title: 'Delete Planned Message',
        message: 'Are you sure you want to delete the planned message?',
        buttonText: 'Delete Planned Message',
      };

      const updateFunction = () => SituationalMessages.deleteSituationalMessage(
        {
          LOB: Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness],
          message: this.state.plannedMessages[index],
          idJwtToken,
        }
      );

      const warningMessage = this.state.plannedMessages[index]
        && this.state.plannedMessages[index].category === 'RECURRING_MEETING'
        ? <div className="endMessageWarning">
          *This will only delete this instance of the meeting, not the entire series.
        </div>
        : null;

      return <EndMessage
        endModalStatus={this.state.showEndPlannedMessage}
        closeModal={this.closeEndPlannedMessageModal}
        message={this.state.plannedMessages[index]}
        updateFunction={updateFunction}
        textValues={textValues}
        updateLocalMessage={this.deleteLocalPlannedMessage}
        warningMessage={warningMessage}
      />;
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  }

  render() {
    const tableValues = this.state.plannedMessages
      ? this.buildTableRows(this.state.plannedMessages)
      : null;
    const editModal = this.state.showModal
      ? this.editAddPlannedMessages(this.state.currentMessageSelectedIndex)
      : null;
    const endModal = this.state.showEndPlannedMessage
      ? this.endPlannedMessage(this.state.currentMessageSelectedIndex)
      : null;

    const currentStateClass = this.state.currentSectionExpanded
      ? ''
      : 'collapsed';

    const expiredStateClass = this.state.expiredSectionExpanded
      ? ''
      : 'collapsed';
    const descriptionModal = this.state.descriptionModal
      ? <DescriptionEditModal
        modalStatus={this.state.descriptionModal}
        closeModal={this.closeDescriptionModal}
        currentLineOfBusiness={this.props.currentLineOfBusiness}
        updateLocalMessage={this.updateLocalDescription}
        description={this.state.description}
        title={'Planned Message Description'}
        messageType={'plannedMessage'}
      />
      : null;

    return (
      <div className="planned-messages">
        <Container className="containerBody mb-2">
          <div className="businessHoursTitle">
            Planned Messages (PST)
            <OverlayTrigger trigger="click" placement="right" overlay={
              <Popover
                id="popover-basic" title="Planned Message Description">
                {this.state.description}
              </Popover>
            }>
              <span>
                <FontAwesomeIcon
                  className="editIcon action-icon mr-2"
                  icon="comment"
                  variant="success"
                />
              </span>
            </OverlayTrigger>
            {this.props.permissions.isGlobalAdmin
              ? <FontAwesomeIcon
                className="editIcon action-icon mr-2"
                icon="pen"
                onClick={() => this.openDescriptionEditModal()}
              /> : null}
          </div>

          <div className="informationText">
            * Please note that recurring meeting instances will not appear below until the start date is no more than 30 days in the future.
          </div>

          <div className={currentStateClass}>
            <div className="formLabel section-header"
              onClick={this.toggleCurrentSection}>
              <FontAwesomeIcon
                className="caret-down"
                icon="caret-down"
              />
              Current and Future
            </div>
            <Col>
              <Collapse
                in={this.state.currentSectionExpanded}>
                <div>
                  <div className="formLabel add-planned-message-link"
                    onClick={() => this.openModal(null)}
                    role="link"
                    tabIndex={0}>
                    <FontAwesomeIcon
                      className="plus add-planned-message-icon"
                      icon="plus"
                    />
                    Add Planned Message
                  </div>
                  <Table striped hover responsive size="sm">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          Description
                        </th>

                        <th>
                          Category
                        </th>
                        <th>
                          Start Date
                        </th>
                        <th>
                          Start Time
                        </th>
                        <th>
                          End Date
                        </th>
                        <th>
                          End Time
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableValues
                        ? tableValues.upcomingOrCurrent
                        : null
                      }
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </Col>
          </div>
          <div className={expiredStateClass}>
            <div className="formLabel section-header"
              onClick={this.toggleExpiredSection}>
              <FontAwesomeIcon
                className="caret-down"
                icon="caret-down"
              />
              Expired
            </div>
            <Col>
              <Collapse in={this.state.expiredSectionExpanded}>
                <div>
                  <Table striped hover responsive size="sm">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          Description
                        </th>

                        <th>
                          Category
                        </th>
                        <th>
                          Start Date
                        </th>
                        <th>
                          Start Time
                        </th>
                        <th>
                          End Date
                        </th>
                        <th>
                          End Time
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableValues
                        ? tableValues.expired
                        : null
                      }
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </Col>
          </div>
        </Container>
        {editModal}
        {endModal}
        {descriptionModal}
      </div>
    );
  }
}
