import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Logger from '../modules/Logger.js';


import LineOfBusiness from '../components/LineOfBusiness';
import TabsOfContent from '../components/TabsOfContent';
import Status from '../components/Status';


import Locations from '../modules/Locations';
import { LinesOfBusinessForAPI } from '../modules/Enums';
import Location from '../components/Location';

export default class AdminSiteContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [],
    };
  }

  componentDidMount() {
    this.loadLocations();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== undefined
      && prevProps.currentLineOfBusiness !== this.props.currentLineOfBusiness
    ) {
      this.loadLocations();
    }
  }

  loadLocations = async () => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      try {
        const locations = await Locations.getLocations(
          LinesOfBusinessForAPI[this.props.currentLineOfBusiness],
          idJwtToken,
        );

        this.setState({
          locations: locations,
          currentLocation: locations[0],
        });
      } catch (err) {
        Logger.error('error: ' + err);
      }
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  }

  changeLocation = async (location) => {
    const selectedLocation = location.target.value;
    if (selectedLocation !== this.state.currentLocation) {
      this.setState({
        currentLocation: selectedLocation,
      });
    }
  };

  render() {
    return (
      <div>
        <Container>
          <div className="titleAndStatusContainer">
            <LineOfBusiness
              linesOfBusiness={this.props.linesOfBusiness}
              currentLineOfBusiness={this.props.currentLineOfBusiness}
              changeLineOfBusiness={this.props.changeLineOfBusiness}
              readOnly={false}
            />
            <Location
              locations={this.state.locations}
              currentLocation={this.state.currentLocation}
              readOnly={false}
              changeLocation={this.changeLocation}
            />
            <Status
              status={this.props.status}
            />
          </div>
          <TabsOfContent
            userName={this.props.userName}
            currentLineOfBusiness={this.props.currentLineOfBusiness}
            linesOfBusiness={this.props.linesOfBusiness}
            changeLineOfBusiness={this.props.changeLineOfBusiness}
            locations={this.state.locations}
            currentLocation={this.state.currentLocation}
            readOnly={false}
            changeLocation={this.changeLocation}
            permissions={this.props.permissions}
          />
        </Container>
      </div>
    );
  }
}
