import Settings from './Settings.js';
import Logger from './Logger.js';
import apigClientFactory from 'aws-api-gateway-client';

export default class EmergencyMessage  {
  static getEmergencyMessage = async (LOB, idJwtToken) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/${LOB}/emergency-message`;
    const method = 'GET';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {};

    const lob = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    Logger.verbose(JSON.stringify(lob));
    return lob.data.emergencyMessage;
  };

  static updateEmergencyMessage = async ({
    LOB,
    message,
    idJwtToken,
  }) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/${LOB}/emergency-message`;
    const method = 'POST';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      emergencyMessage: message,
    };

    return await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    ).then((res) => {
      Logger.verbose(
        `UpdateEmergecyMessage Response: ${JSON.stringify(res)}`
      );

      return res.status === 200;
    }).catch((ex) => {
      Logger.error('Exception UpdateEmergecyMessage: ' +
        JSON.stringify(ex));
      return false;
    });
  };
}
