export default class Settings  {
  static starbucksAPIURL = 'https://cs7hrg3l13.execute-api.us-east-1.amazonaws.com/Prod';
  static getLineOfBusinessHours = (LOB) => {
    return this.starbucksAPIURL + '/lob/' + LOB + '/business-hours';
  }

  static getEmergencyMessage = (LOB) => {
    return this.starbucksAPIURL + '/lob/' + LOB + '/emergency-message';
  }

  static getAllLinesOfBusiness = (LOB) => {
    return this.starbucksAPIURL + '/lob/';
  }

  static getSituationalMessages = (LOB) => {
    return this.starbucksAPIURL + '/lob/' + LOB + '/situationalmessage';
  }

  static getTemporaryMessages = (LOB) => {
    return this.starbucksAPIURL + '/lob/' + LOB + '/temporarymessages';
  }

  static getLOBLocations = (LOB) => {
    return this.starbucksAPIURL + '/lob/' + LOB + '/locations';
  }

  static getLOBLocationMessages = (LOB) => {
    return this.starbucksAPIURL + '/lob/' + LOB + '/locationclosures';
  }

  static timeOut = 1800000;

  static getDescription = (messageType) => {
    return this.starbucksAPIURL + '/lob/description/' + messageType;
  }

  static getResourceManagerStatus = (LOB) => {
    return this.starbucksAPIURL + '/lob/' + LOB + '/status';
  }

  static getResourceManagerStatus = (LOB) => {
    return this.starbucksAPIURL + '/lob/' + LOB + '/status';
  }

  static getResourceManagerStatus = (LOB) => {
    return this.starbucksAPIURL + '/lob/' + LOB + '/status';
  }

  static getLexUtterances = (LOB) => {
    return this.starbucksAPIURL + '/lob/' + LOB + '/utterances';
  }

  static postLexUtterances = (intent) => {
    return this.starbucksAPIURL + '/lob/utterances/' + intent;
  }

  static getCallTypeSchedule = (LOB) => {
    return this.starbucksAPIURL + `/lob/${LOB}/callTypeScheduleRM`;
  }

  static getLexIntents = (LOB) => {
    return this.starbucksAPIURL + `/lob/${LOB}/intents`;
  }

  static starbucksConnectURL =
    'https://sbux-test.awsapps.com/connect/contact-trace-records/details/';

  static getLexAliases = (LOB) => {
    return this.starbucksAPIURL + `/lob/${LOB}/bot-aliases`;
  }

  static deployLexAlias = (LOB) => {
    return this.starbucksAPIURL + `/lob/${LOB}/deploy-alias`;
  }

  static cognito = {
    REGION: 'us-east-1',
    APP_CLIENT_ID: '77enktmp14k9ret8om8bt8rjok',
    SCOPE: ['openid'],
    DOMAIN: 'onenumber-prod.auth.us-east-1.amazoncognito.com',
    REDIRECT_SIGN_IN_URL: 'https://connectadmin.starbucks.com/',
    REDIRECT_SIGN_OUT_URL: 'https://sts-vis-cloud1.starbucks.com/ofis/public/signout.aspx?wa=wsignout1.0&wreply=/ofis/public/sbuxLogOut.html',
    USERPOOL_ID: 'us-east-1_twrLKRCpH',
    USERPOOL_WEBCLIENT_ID: '77enktmp14k9ret8om8bt8rjok',
    FEDERATED_PROVIDER: 'sts-vis-cloud1',
  }
}
