import Settings from './Settings.js';
import Logger from './Logger.js';
import apigClientFactory from 'aws-api-gateway-client';

export default class SituationalMessages  {
  static getSituationalMessages = async (LOB, idJwtToken) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/${LOB}/situationalmessage`;
    const method = 'GET';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {};

    const lob = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    Logger.verbose(JSON.stringify(lob));
    return lob.data.situationalMessages;
  };

  static updateSituationalMessage = async (
    LOB,
    previousSituationalMessage,
    updatedSituationalMessage,
    idJwtToken,
  ) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/${LOB}/situationalmessage`;
    const method = 'POST';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      previousSituationalMessage,
      updatedSituationalMessage,
    };

    try {
      const updateSituationalMessages = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );

      Logger.verbose(JSON.stringify(
        `Line of Business updated: ${updateSituationalMessages.data.LineOfBusiness}`));
      return true;
    } catch (err) {
      Logger.error('Exception Update situational message: ' +
        JSON.stringify(err));
      return false;
    }
  }

  static deleteSituationalMessage = async ({
    LOB,
    message,
    idJwtToken,
  }) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/${LOB}/situationalmessage`;
    const method = 'DELETE';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      deleteSituationalMessage: message,
    };

    try {
      const deletedSituationalMessage = await apigClient.invokeApi(
        pathParams,
        pathTemplate,
        method,
        additionalParams,
        body
      );

      Logger.verbose(JSON.stringify(
        `Line of Business updated: ${deletedSituationalMessage.data.LineOfBusiness}`));
      return true;
    } catch (err) {
      Logger.error('Exception deleting situational message: ' +
        JSON.stringify(err));
      return false;
    }
  }
}
