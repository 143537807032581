import Amplify from 'aws-amplify';
import Settings from './Settings.js';

export default class AmplifySettings {
  static oauth = {
    // Domain name
    domain: Settings.cognito.DOMAIN,
    // Authorized scopes
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    // Callback URL
    redirectSignIn: Settings.cognito.REDIRECT_SIGN_IN_URL, // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
    // Sign out URL
    redirectSignOut: Settings.cognito.REDIRECT_SIGN_OUT_URL, // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    responseType: 'token',
    // optional, for Cognito hosted ui specified options
    options: {
      // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
      AdvancedSecurityDataCollectionFlag: true,
    },
    // if set, redirects user directly to the provider for authentication
    federatedProvider: Settings.cognito.FEDERATED_PROVIDER,
  };

  static config = () => {
    Amplify.configure({
      Auth: {
        region: Settings.cognito.REGION,
        userPoolId: Settings.cognito.USERPOOL_ID,
        userPoolWebClientId: Settings.cognito.USERPOOL_WEBCLIENT_ID,
        mandatorySignIn: true,
        authenticationFlowType: 'USER_SRP_AUTH',
        // oauth: oauth

      },
      API: {
        endpoints: [
          {
            name: 'MyAPIGatewayAPI',
            endpoint: Settings.starbucksAPIURL,
            // custom_header: async () => {
            //   // Use this with identity pools (???)
            //   // return { Authorization : 'token' }
            //   // Alternatively, with Cognito User Pools use this:
            //   return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
            // }
          },
        ],
      },
    });
  };
}
