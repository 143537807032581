import React, { Component } from 'react';
import { Table, Modal, Button, Form, Col } from 'react-bootstrap';

import Utils from '../modules/Utils';

import LineOfBusiness from './LineOfBusiness';

import { TimePicker } from 'antd';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class EditableHoursTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateFailed: null,
      saveInProgress: false,
      hours: this.props.editableHours
        ? this.sortHours(this.props.editableHours.hours.slice())
        : [],
      isOpen24Hours: this.props.editableHours.isOpen24Hours || false,
      isClosed: this.props.editableHours.isClosed || false,
      startTime: null,
      endTime: null,
      errorMessage: null,
      dateAndTimeIsValid: true,
    };

    this.handleIsClosedChange = this.handleIsClosedChange.bind(this);
    this.handleIsOpen24HoursChange = this.handleIsOpen24HoursChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);

    this.onAddHours = this.onAddHours.bind(this);
    this.onRemoveHours = this.onRemoveHours.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  sortHours = hours => {
    return hours.sort((a, b) => {
      if (a.startTime < b.startTime) {
        return -1;
      }

      if (a.startTime > b.startTime) {
        return 1;
      }

      return 0;
    });
  };

  handleTimeChange = (startOrEnd, value) => {
    if (startOrEnd === 'startTime') {
      this.setState({ startTime: value, errorMessage: null });
    }

    if (startOrEnd === 'endTime') {
      this.setState({ endTime: value, errorMessage: null });
    }
  };

  handleIsClosedChange(event) {
    let newState = {
      isClosed: event.target.checked,
      errorMessage: null,
    };

    if (newState.isClosed) {
      newState.isOpen24Hours = false;
    }

    this.setState(newState);
  }

  handleIsOpen24HoursChange(event) {
    let newState = {
      isOpen24Hours: event.target.checked,
      errorMessage: null,
    };

    if (newState.isOpen24Hours) {
      newState.isClosed = false;
    }

    this.setState(newState);
  }

  handleClose = () => {
    this.props.closeModal();
  };

  onAddHours = () => {
    if (!this.state.startTime || !this.state.endTime) {
      return;
    }

    const newHours = {
      startTime: Utils.convertToTwentyFourHour(this.state.startTime),
      endTime: Utils.convertToTwentyFourHour(this.state.endTime),
    };

    if (!Utils.isStartTimeBeforeEndTime(newHours)) {
      this.setState({
        errorMessage: 'Please make sure the end time is after the start time.',
      });
    } else {
      const hours = this.state.hours.concat(newHours);
      this.setState({
        hours: this.sortHours(hours),
        startTime: null,
        endTime: null,
        errorMessage: null,
      });
    }
  };

  onRemoveHours = hours => {
    const updatedHours = this.state.hours.filter(
      h => h.startTime !== hours.startTime && h.endTime !== hours.endTime
    );

    this.setState({ hours: updatedHours, errorMessage: null });
  };

  onSubmit = async () => {
    if (this.state.saveInProgress) {
      return;
    }

    if (!this.isValidHours()) {
      this.setState({
        errorMessage: 'Please enter valid, disjoint business hours.',
      });

      return;
    }

    this.setState({
      saveInProgress: true,
    });

    let businessHours = {};
    if (this.state.isClosed) {
      businessHours = {
        isClosed: true,
        isOpen24Hours: false,
        hours: [],
      };
    } else if (this.state.isOpen24Hours) {
      businessHours = {
        isClosed: false,
        isOpen24Hours: true,
        hours: [],
      };
    } else {
      businessHours = {
        isClosed: false,
        isOpen24Hours: false,
        hours: this.state.hours.slice(),
      };
    }

    const isSuccess = await this.props.setBusinessHours(businessHours);

    if (isSuccess) {
      this.props.closeModal(true);
    } else {
      this.setState({
        errorMessage: 'Failed to update business hours. Please try again.',
        saveInProgress: false,
      });
      return;
    }
  };

  isValidHours = () => {
    if (this.state.isClosed || this.state.isOpen24Hours) {
      return true;
    }

    if (!Array.isArray(this.state.hours) || this.state.hours.length === 0) {
      return false;
    }

    const sortedByStartTime = this.sortHours(this.state.hours);

    if (sortedByStartTime.find(h => h.startTime > h.endTime)) {
      return false;
    }

    for (let i = 0; i < sortedByStartTime.length - 1; i++) {
      if (sortedByStartTime[i].endTime > sortedByStartTime[i + 1].startTime) {
        return false;
      }
    }

    return true;
  };

  buildHoursTableRows = hours => {
    if (!Array.isArray(hours) || hours.length === 0) {
      return [];
    }

    return hours.map((hours, index) => {
      const start = moment(hours.startTime, 'h:mm a').format('h:mm a');
      const end = moment(hours.endTime, 'h:mm a').format('h:mm a');

      return (
        <tr key={index}>
          <td className="tableCell">{start}</td>
          <td className="tableCell">{end}</td>
          <td className="tableCell">
            <FontAwesomeIcon
              className="editIcon action-icon mr-2 denyCircle text-danger"
              icon={['far', 'times-circle']}
              onClick={() => this.onRemoveHours(hours)}
            />
          </td>
        </tr>
      );
    });
  };

  getSaveButton = () => {
    return this.state.saveInProgress ? (
      <Button disabled type="submit" className="submitCloseButtons">
        Saving...
      </Button>
    ) : (
      <Button
        type="submit"
        className="submitCloseButtons"
        onClick={this.onSubmit}
      >
        Save
      </Button>
    );
  };

  getCloseButton = () => {
    return this.state.saveInProgress ? (
      <Button
        disabled
        variant="outline-secondary"
        onClick={this.handleClose}
        className="submitCloseButtons"
      >
        Close
      </Button>
    ) : (
      <Button
        variant="outline-secondary"
        onClick={this.handleClose}
        className="submitCloseButtons"
      >
        Close
      </Button>
    );
  };

  render() {
    const title = `Edit Business Hours - ${this.props.editableDay}`;
    const timeFormat = 'h:mm a [PST]';
    const hoursRows = this.buildHoursTableRows(this.state.hours);
    const saveButton = this.getSaveButton();
    const closeButton = this.getCloseButton();

    return (
      <Modal show={this.props.modalStatus} onHide={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LineOfBusiness
            linesOfBusiness={this.props.linesOfBusiness}
            currentLineOfBusiness={this.props.currentLineOfBusiness}
            changeLineOfBusiness={this.props.changeLineOfBusiness}
            readOnly={true}
          />

          <Form.Group className="mb-10">
            <Form.Check
              id="isClosedCheckbox"
              checked={this.state.isClosed}
              onChange={this.handleIsClosedChange}
              label="Closed"
            />

            <Form.Check
              id="isOpen24HoursCheckbox"
              checked={this.state.isOpen24Hours}
              onChange={this.handleIsOpen24HoursChange}
              label="Open 24 Hours"
            />
          </Form.Group>

          {!this.state.isClosed && !this.state.isOpen24Hours && (
            <>
              <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                className="mb-10"
              >
                <thead className="tableHead">
                  <tr>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th />
                  </tr>
                </thead>
                {hoursRows.length > 0 ? (
                  <tbody>{hoursRows}</tbody>
                ) : (
                  <tfoot>
                    <tr>
                      <td colSpan="3">No business hours</td>
                    </tr>
                  </tfoot>
                )}
              </Table>
              <Form.Group className="mt-2">
                <Form.Row>
                  <Col>
                    <TimePicker
                      use12Hours
                      value={this.state.startTime}
                      format={timeFormat}
                      onChange={value =>
                        this.handleTimeChange('startTime', value)
                      }
                    />
                  </Col>
                  <Col>
                    <TimePicker
                      use12Hours
                      value={this.state.endTime}
                      format={timeFormat}
                      onChange={value =>
                        this.handleTimeChange('endTime', value)
                      }
                    />
                  </Col>
                  <Col>
                    <Button variant="primary" onClick={this.onAddHours}>
                      Add
                    </Button>
                  </Col>
                  <Col />
                </Form.Row>
              </Form.Group>
              {this.state.errorMessage && (
                <div className="notValidHoursAlert mb-0 mt-0">
                  {this.state.errorMessage}
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {saveButton}
          {closeButton}
        </Modal.Footer>
      </Modal>
    );
  }
}
