import React, { Component } from 'react';
import {Form} from 'react-bootstrap';

export default class Location extends Component {
  buildLocationElement = (
    // TODO: linesOfBusiness
    locations,
    currentLocation,
    readOnly,
  ) => {
    if (locations.length > 1) {
      const arrayOfLocations = locations.map((x, index) => {
        return <option key={index}>
          {x}
        </option>;
      });

      return readOnly
        ? <Form>
          <Form.Group
            controlId="exampleForm.ControlSelect1"
            className="lineOfBusinessFormTitle">

            <div className="lineOfBusinessTitle">
              Locations
            </div>

            <Form.Control
              as="select"
              value={currentLocation}
              onChange={this.props.changeLocation}
              disabled>
              {arrayOfLocations}
            </Form.Control>
          </Form.Group>
        </Form>
        : <Form>
          <Form.Group
            controlId="exampleForm.ControlSelect1"
            className="lineOfBusinessFormTitle">

            <div className="lineOfBusinessTitle">
              Locations
            </div>
            <div className="selectFormSizeControl">
              <Form.Control
                as="select"
                value={currentLocation}
                onChange={this.props.changeLocation}>
                {arrayOfLocations}
              </Form.Control>
            </div>
          </Form.Group>
        </Form>;
    } else {
      return <div>
        <div className="lineOfBusinessTitle">
          Locations
        </div>
        {locations[0]}
      </div>;
    }
  };

  render() {
    const locationElements = this.buildLocationElement(
      this.props.locations,
      this.props.currentLocation,
      this.props.readOnly,
    );
    return (
      <div className="locations">
        {locationElements}
      </div>
    );
  }
}
