import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import SituationalMessages from './SituationalMessages';
import RecurringMessages from './RecurringMessages';
import TemporaryMessages from './TemporaryMessages';
import LocationClosure from './LocationClosures';

export default class TabsOfContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Tabs
        id="messageOrHOB"
        activeKey={this.state.key}
        className="nav-tabs">

        <Tab
          eventKey="situationalMessages"
          title="Situational Messages">
          <SituationalMessages
            currentLineOfBusiness={this.props.currentLineOfBusiness}
            userName={this.props.userName}
            permissions={this.props.permissions}
          />
        </Tab>

        <Tab
          eventKey="recurringMessages"
          title="Recurring Situational Messaging">
          <RecurringMessages
            currentLineOfBusiness={this.props.currentLineOfBusiness}
            userName={this.props.userName}
            permissions={this.props.permissions}
          />
        </Tab>

        <Tab
          eventKey="temporaryMessages"
          title="Temporary Messages">
          <TemporaryMessages
            currentLineOfBusiness={this.props.currentLineOfBusiness}
            userName={this.props.userName}
            permissions={this.props.permissions}
          />
        </Tab>

        <Tab
          eventKey="locationClosures"
          title="Location Closures">
          <LocationClosure
            currentLineOfBusiness={this.props.currentLineOfBusiness}
            currentLocation={this.props.currentLocation}
            userName={this.props.userName}
            permissions={this.props.permissions}
          />
        </Tab>
      </Tabs>
    );
  }
}
