import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import Logger from '../modules/Logger.js';
import { Modal, Form, Button} from 'react-bootstrap';
import LineOfBusiness from './LineOfBusiness';

import Utils from '../modules/Utils';
import Enums from '../modules/Enums';
import Locations from '../modules/Locations';
import Constants from '../modules/Constants';

import DateTime from 'react-datetime';

export default class EditAddLocationMessages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      saveInProgress: false,
      ...this.formatLocationClosureMessageState(
        this.props.locationClosureMessage
      ),
    };
  }

  LocationClosureMessageKeyNames = {
    START: 'startDateTime',
    END: 'endDateTime',
    ENGLISH : 'englishResponse',
    FRENCH: 'frenchResponse',
    START_DATE_TIME: 'startDateTime',
    END_DATE_TIME: 'endDateTime',
  };

  formatLocationClosureMessageState = (locationClosureMessage) => {
    if (!locationClosureMessage) {
      return {
        formisValid: true,
        descriptionIsValid: true,
        dateAndTimeIsValid: true,
        startDateTime: Date.now(),
        endDateTime: Date.now(),
        description: '',
        updateSucessful : true,
        previousLocationClosureMessage: null,
        startTimeValid: true,
        endTimeValid: true,
      };
    } else {
      return {
        formisValid: true,
        descriptionIsValid: true,
        dateAndTimeIsValid: true,
        startDateTime: Number(locationClosureMessage.startDateTime),
        endDateTime: Number(locationClosureMessage.endDateTime),
        description: locationClosureMessage.description,
        updateSucessful : true,
        previousLocationClosureMessage: {...locationClosureMessage},
        startTimeValid: true,
        endTimeValid: true,
      };
    }
  }

  handleDescriptionChange = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const descriptionIsValid = Utils.isDescriptionValid(event.target.value);

    this.setState({
      descriptionIsValid: descriptionIsValid,
      description: event.target.value,
    });
  }

  handleDateAndTime = (isStart, event, date) => {
    const dateAndTime = Date.parse(date);

    const currentTimeSelectionObj = {
      currentTime : Date.now(),
    };

    const currenttimeSelection = isStart
      ? {
        ...currentTimeSelectionObj,
        selectedStartTime : dateAndTime,
        selectedEndTime : this.state.endDateTime,
      }
      : {
        ...currentTimeSelectionObj,
        selectedStartTime : this.state.startDateTime,
        selectedEndTime : dateAndTime,
      };

    const startOrEndKeyName = isStart
      ? this.LocationClosureMessageKeyNames.START_DATE_TIME
      : this.LocationClosureMessageKeyNames.END_DATE_TIME;

    const dateAndTimeIsValid = Utils.isPlannedDateAndTimeValid(
      currenttimeSelection
    );

    const isStartTimeValid = Utils.isLocationClosureStartTimeValid(
      currenttimeSelection
    );

    const isEndTimeValid = Utils.isPlannedEndTimeValid(
      currenttimeSelection
    );

    this.setState({
      dateAndTimeIsValid,
      [startOrEndKeyName]: dateAndTime,
      startTimeValid: isStartTimeValid,
      endTimeValid: isEndTimeValid,
    });
  }

  handleValidation = (elements, state) => {
    const currentTimeSelectionObj = {
      selectedStartTime : this.state.startDateTime,
      selectedEndTime : this.state.endDateTime,
      currentTime : Date.now(),
    };

    const isDescriptionValid = Utils.isDescriptionValid(
      this.state.description
    );
    const isDateAndTimeValid = Utils.isPlannedDateAndTimeValid(
      currentTimeSelectionObj
    );

    const isStartTimeValid = Utils.isLocationClosureStartTimeValid(
      currentTimeSelectionObj
    );

    const isEndTimeValid = Utils.isPlannedEndTimeValid(
      currentTimeSelectionObj
    );

    const isFormValid = Utils.isLocationClosureFormValid(
      isDescriptionValid,
      isDateAndTimeValid,
      isStartTimeValid,
      isEndTimeValid,
    );

    return {
      descriptionIsValid: isDescriptionValid,
      dateAndTimeIsValid: isDateAndTimeValid,
      startTimeValid: isStartTimeValid,
      endTimeValid: isEndTimeValid,
      formisValid: isFormValid,
    };
  }

  updateLocationClosureMessage = async (previousMessage, newMessage) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      const isUpdateSuccess = await Locations
        .updateLocationClosureMessage(
          `${Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness]}_${this.props.currentLocation}`,
          previousMessage || null,
          newMessage,
          idJwtToken,
        );

      return isUpdateSuccess;
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  };

  handleSubmit = async (event) => {
    if (this.state.saveInProgress) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    this.setState({saveInProgress: true});

    const checkValidation = this.handleValidation(event.target, this.state);
    
    if (checkValidation.formisValid) {
      const copyState = this.state;
      copyState.category = Enums.MessageCategory[copyState.category];
      copyState.type = Enums.MessageType[copyState.type];
      const newLocationClosureMessage = Utils.formatSituationalMessageToAPI(
        copyState,
        this.props.userName
      );

      const isUpdateSuccess = await this.
        updateLocationClosureMessage(
          this.state.previousLocationClosureMessage,
          newLocationClosureMessage,
        );

      if (isUpdateSuccess) {
        this.props.updateLocalMessage(newLocationClosureMessage);
        this.props.closeModal();
      } else {
        this.setState({
          updateSucessful: false,
          saveInProgress: false,
        });
      }
    } else {
      this.setState({
        descriptionIsValid: checkValidation.descriptionIsValid,
        dateAndTimeIsValid: checkValidation.dateAndTimeIsValid,
        responseIsValid: checkValidation.responseIsValid,
        formisValid: checkValidation.formisValid,
        endTimeValid: checkValidation.endTimeValid,
        startTimeValid: checkValidation.startTimeValid,
        updateSucessful: true,
        saveInProgress: false,
      });
    }
  }

  handleClose = () => {
    this.props.closeModal();
  }

  getTitle = () => {
    const currentOrExpiredOrUpcoming = Utils.messageStatus(
      this.props.locationClosureMessage
    );
    switch (currentOrExpiredOrUpcoming) {
      case 'UPCOMING':
      case 'CURRENT':
        return 'Update Location Closure Message';

      case 'EXPIRED':
      default:
        return 'Create Location Closure Message';
    }
  }

  getSaveButton = () => {
    return this.state.saveInProgress
      ? <Button
        disabled
        type="submit"
        className="submitCloseButtons">
        Saving...
      </Button>
      : <Button
        type="submit"
        className="submitCloseButtons">
        Save
      </Button>;
  }

  getCloseButton = () => {
    return this.state.saveInProgress
      ? <Button
        disabled
        variant="outline-secondary"
        onClick={this.handleClose}
        className="submitCloseButtons">
        Close
      </Button>
      : <Button
        variant="outline-secondary"
        onClick={this.handleClose}
        className="submitCloseButtons">
        Close
      </Button>;
  }

  render() {
    const modalTitle = this.getTitle();
    const saveButton = this.getSaveButton();
    const closeButton = this.getCloseButton();

    return (
      <div>
        <Modal show={this.props.modalStatus} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>


            <Form
              noValidate
              onSubmit={this.handleSubmit}
              className="conatiner">

              <div className="formSection noBorder">
                <LineOfBusiness
                  linesOfBusiness={[this.props.currentLineOfBusiness]}
                  currentLineOfBusiness={this.props.currentLineOfBusiness}
                  readOnly={true}
                  className="col-12"
                />

                <Form.Group controlId="Description">
                  <Form.Label
                    className="formLabel">
                    Description
                  </Form.Label>

                  <Form.Control
                    required
                    type="text"
                    defaultValue={this.state.description}
                    onChange={this.handleDescriptionChange}
                    name="Description"
                  />
                </Form.Group>
                <div className={this.state.descriptionIsValid
                  ? 'removeContent'
                  : 'displayContent validateForm'}>
        Please make sure the description length is between 1 to 50 characters.
                </div>
              </div>
              <h5 className="formSectionHeader">Time</h5>

              <div className="formSection container">
                <div className="formGroup">
                  <Form.Label className="formLabel">
                    Start Time
                  </Form.Label>

                  <div>
                    <DateTime
                      defaultValue={
                        new Date(Number(this.state.startDateTime))
                      }
                      timeFormat={
                        Constants.DateFormat.TIME
                      }
                      name="startDateTime"
                      className="dateTimePicker"
                      onChange={this.handleDateAndTime.bind(
                        this, true, this.name)}
                    />
                  </div>
                </div>

                <div className="formGroup">
                  <Form.Label className="formLabel">
                    End Time
                  </Form.Label>

                  <div>
                    <DateTime
                      defaultValue={
                        new Date(Number(this.state.endDateTime))
                      }
                      timeFormat={
                        Constants.DateFormat.TIME
                      }
                      name="endDateTime"
                      className="dateTimePicker"
                      onChange={this.handleDateAndTime.bind(
                        this, false, this.name
                      )}
                    />
                  </div>
                </div>
                <div className={this.state.dateAndTimeIsValid
                  ? 'removeContent'
                  : 'displayContent validateForm'}>
                  Please make sure the end date is after the start date.
                </div>

                <div className={this.state.startTimeValid
                  ? 'removeContent'
                  : 'displayContent validateForm'}>
                  The start date and time must be after today.
                </div>

                <div className={this.state.endTimeValid
                  ? 'removeContent'
                  : 'displayContent validateForm'}>
                  The end date and time must be after today.
                </div>
              </div>

              {saveButton}
              {closeButton}

              <div className={
                this.state.updateSucessful
                  ? 'removeContent'
                  : 'displayContent validateForm'}>
                Updating Location Closure message was not successful. Please try again.
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
