import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Logger from '../modules/Logger.js';

import RDS from '../modules/RDS';

export default class GenerateRDSKey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rdsKey: null,
      saveInProgress: false,
    };
  }

  fetchToken = async () => {
    this.setState({saveInProgress: true});
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      const rds =
        await RDS.get(
          idJwtToken,
        );

      this.setState({
        rdsKey: rds.authToken,
        username: rds.username,
        hostname: rds.hostname,
        port: rds.port,
        saveInProgress: false,
      });
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  };

  tempKey = () => {
    return <div>
      <div className="formLabel">
        Host Name
      </div>
      <div className="adminValue">
        {this.state.hostname}
      </div>
      <div className="formLabel">
        Port
      </div>
      <div className="adminValue">
        {this.state.port}
      </div>
      <div className="formLabel">
        User Name
      </div>
      <div className="adminValue">
        {this.state.username}
      </div>
      <div className="formLabel">
        Temporary Key
      </div>
      <div className="adminValue adminKey">
        {this.state.rdsKey}
      </div>
    </div>;
  };
  render() {
    const tempKey = this.state.rdsKey
      ? this.tempKey()
      : null;
    return (
      <div className="planned-messages">
        <Container className="containerBody mb-2">
          <div className="businessHoursTitle">
            Generate RDS Temporary Key
          </div>

          {tempKey}

          <Button
            disabled={this.state.saveInProgress}
            onClick={this.fetchToken}>
            Generate Token
          </Button>
        </Container>
      </div>
    );
  }
}
