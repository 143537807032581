import React, { Component } from 'react';
import {
  Container, Table, Col, Collapse, OverlayTrigger, 
  Popover,
} from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logger from '../modules/Logger.js';
import Description from '../modules/Description';
import EditTempMesssageModal from './EditTempMessage';
import DescriptionEditModal from './DescriptionEditModal.js';
import EndMessage from './EndMessage';
import TemporaryMessages from '../modules/TemporaryMessages';
import Enums from '../modules/Enums';
import TimeZone from 'moment-timezone';
import Utils from '../modules/Utils';
import Constants from '../modules/Constants';

TimeZone.tz.setDefault(Constants.TimeZone.PACIFIC_TIME);

export default class PlannedMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tempMessages: null,
      showModal: false,
      currentMessageSelectedIndex: null,
      showEndMessage: false,
      currentSectionExpanded: true,
      expiredSectionExpanded: false,
      descriptionModal: false,
      description: null,
      idJwtToken: null,
    };
  }

  openModal = (index) => {
    this.setState({
      showModal: true,
      currentMessageSelectedIndex: index,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      currentMessageSelectedIndex: null,
    });
  };

  updateLocalTempMessage = (newTempMessage) => {
    let copyState = this.state.tempMessages;

    this.state.currentMessageSelectedIndex === null
      ? copyState = { ...copyState, newTempMessage }
      : copyState[this.state.currentMessageSelectedIndex] = newTempMessage;

    this.setState({
      tempMessages: { ...copyState },
    });
  }

  componentDidMount() {
    this.loadTemporaryMessages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== undefined
      && prevProps.currentLineOfBusiness !== this.props.currentLineOfBusiness
    ) {
      this.loadTemporaryMessages();
    }
  }

  async loadTemporaryMessages() {
    if (this.props.currentLineOfBusiness === undefined) {
      return;
    }

    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      const tempMessages =
        await TemporaryMessages.getTemporaryMessages(
          Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness],
          idJwtToken,
        );

      const description =
        await Description.getDescriptionMessage(
          'temporaryMessage',
          idJwtToken,
        );

      this.setState({
        tempMessages: tempMessages,
        description,
        idJwtToken,
      });
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  }

  buildStatusMessageDivElements = (messageStatus) => {
    let statusClassName;
    switch (messageStatus) {
      case 'Active':
        statusClassName = 'activeStatus';
        break;
      case 'Scheduled':
        statusClassName = 'scheduledStatus';
        break;
      default:
        statusClassName = null;
        break;
    }

    return <div className={statusClassName}>
      {messageStatus}
    </div>;
  }

  openEndMessageModal = (index) => {
    this.setState({
      showEndMessage: true,
      currentMessageSelectedIndex: index,
    });
  }

  closeEndMessageModal = () => {
    this.setState({
      showEndMessage: false,
      currentMessageSelectedIndex: null,
    });
  }

  openDescriptionEditModal = () => {
    this.setState({
      descriptionModal: true,
    });
  }

  closeDescriptionModal = () => {
    this.setState({
      descriptionModal: false,
    });
  }

  updateLocalDescription = (newDescription) => {
    this.setState({
      description: newDescription,
    });

    return true;
  }

  buildTableRows = (tempMessages) => {
    const keys = Object.keys(tempMessages);

    const arrayOfTableValues = keys.map((key, index) => {
      const messageStatus = Utils.messageStatus(
        tempMessages[key],
      );

      let startMonthDayYear;
      let startTime;
      let endMonthDayYear;
      let endTime;

      if (messageStatus === 'EXPIRED') {
        startMonthDayYear = null;
        startTime = null;
        endMonthDayYear = null;
        endTime = null;
      } else {
        startMonthDayYear = TimeZone(Number(
          tempMessages[key].startDateTime))
          .format(Constants.DateFormat.MONTH_DAY_YEAR);
        startTime = TimeZone(Number(
          tempMessages[key].startDateTime))
          .format(Constants.DateFormat.TIME);
        endMonthDayYear = tempMessages[key].endDateTime
          ? TimeZone(Number(tempMessages[key].endDateTime))
            .format(Constants.DateFormat.MONTH_DAY_YEAR)
          : 'Undetermined';
        endTime = tempMessages[key].endDateTime
          ? TimeZone(Number(tempMessages[key].endDateTime))
            .format(Constants.DateFormat.TIME)
          : 'Undetermined';
      }
      const statusValue = this.buildStatusMessageDivElements(
        Enums.MessageType[messageStatus]
      );
      const value = <tr key={index}>
        <td className="iconColumn">
          <FontAwesomeIcon
            className="editIcon action-icon mr-2"
            icon="pen"
            onClick={() => this.openModal(key)}
          />
          <FontAwesomeIcon
            className="trash-alt action-icon"
            icon="trash-alt"
            onClick={() => this.openEndMessageModal(key)}
          />
        </td>
        <td className="">{key}</td>
        <td className="descriptionColumn">{tempMessages[key].description}</td>
        <td className="timeColumn">{statusValue}</td>
        <td className="dateColumn">{startMonthDayYear}</td>
        <td className="timeColumn">{startTime}</td>
        <td className="dateColumn">{endMonthDayYear}</td>
        <td className="timeColumn">{endTime}</td>
      </tr>;

      return value;
    });

    return arrayOfTableValues;
  }

  editAddPlannedMessages = (index) => {
    return <EditTempMesssageModal
      modalStatus={this.state.showModal}
      closeModal={this.closeModal}
      tempMessage={this.state.tempMessages[index]}
      currentLineOfBusiness={this.props.currentLineOfBusiness}
      updateLocalTempMessage={this.updateLocalTempMessage}
      index={index}
      userName={this.props.userName}
      permissions={this.props.permissions}
    />;
  }

  endMessage = (index) => {
    const idJwtToken = this.state.idJwtToken;

    if (idJwtToken) {
      const textValues = {
        title: 'End Temporary Message',
        message: 'Are you sure you want to end the temporary message?',
        buttonText: 'End Temporary Message',
      };

      const message = { ...this.state.tempMessages[index] };
      message.endDateTime = String(Date.now());
      message.lastUpdatedBy = this.props.userName;

      const updateFunction = () => TemporaryMessages.updateTempMessage(
        {
          LOB: Enums.LinesOfBusinessForAPI[this.props.currentLineOfBusiness],
          message: message,
          index: index,
          idJwtToken,
        }
      );

      return <EndMessage
        endModalStatus={this.state.showEndMessage}
        closeModal={this.closeEndMessageModal}
        updateLocalMessage={this.updateLocalTempMessage}
        message={message}
        updateFunction={updateFunction}
        textValues={textValues}
      />;
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  }

  render() {
    const tableValues = this.state.tempMessages
      ? this.buildTableRows(this.state.tempMessages)
      : null;
    const editModal = this.state.showModal
      ? this.editAddPlannedMessages(this.state.currentMessageSelectedIndex)
      : null;
    const endModal = this.state.showEndMessage
      ? this.endMessage(this.state.currentMessageSelectedIndex)
      : null;
    const descriptionModal = this.state.descriptionModal
      ? <DescriptionEditModal
        modalStatus={this.state.descriptionModal}
        closeModal={this.closeDescriptionModal}
        currentLineOfBusiness={this.props.currentLineOfBusiness}
        updateLocalMessage={this.updateLocalDescription}
        description={this.state.description}
        title={'Temporaray Message Description'}
        messageType={'temporaryMessage'}
      />
      : null;
    return (
      <div className="planned-messages">
        <Container className="containerBody mb-2">
          <div className="businessHoursTitle">
            Temporary Messages (PST)
            <OverlayTrigger trigger="click" placement="right" overlay={
              <Popover
                id="popover-basic" title="Temporary Message Description">
                {this.state.description}
              </Popover>
            }>
              <span>
                <FontAwesomeIcon
                  className="editIcon action-icon mr-2"
                  icon="comment"
                  variant="success"
                />
              </span>
            </OverlayTrigger>
            {this.props.permissions.isGlobalAdmin ? <FontAwesomeIcon
              className="editIcon action-icon mr-2"
              icon="pen"
              onClick={() => this.openDescriptionEditModal()}
            /> : null}
          </div>

          <Table striped hover responsive size="sm">
            <thead>
              <tr>
                <th></th>
                <th>
                  ID
                </th>

                <th>
                  Description
                </th>
                <th>
                  Status
                </th>
                <th>
                  Start Date
                </th>
                <th>
                  Start Time
                </th>
                <th>
                  End Date
                </th>
                <th>
                  End Time
                </th>
              </tr>
            </thead>

            <tbody>
              {tableValues}
            </tbody>
          </Table>
        </Container>
        {editModal}
        {endModal}
        {descriptionModal}
      </div>
    );
  }
}
