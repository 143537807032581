export default class BulkImport {
  static validate(body) {
    if (!Array.isArray(body) || body.length < 1 || !Array.isArray(body[0])) {
      return { isValid: false, errorMessage: 'Error: The file upload must be in .csv format.  Please correct and re-upload your file.' };
    }
    const missingColumnNames = checkColumnHeaders(body[0]);
    if (missingColumnNames.length > 0) {
      return {
        isValid: false,
        errorMessage: `Error: The spreadsheet contains invalid column names.  Refer to the Export file for the correct columns.`,
      };
    }
    if (body.length < 2) {
      return { isValid: false, errorMessage: 'No users found.' };
    }
    return { isValid: true };
  }
}

const REQUIRED_COLUMN_HEADERS = [
  'FirstName',
  'LastName',
  'Username',
  'RoutingProfile',
  'SecurityProfiles',
  'SoftPhoneAutoAccept',
  'ACWtimeout(seconds)',
];
const checkColumnHeaders = (actualHeaders) => {
  // exected format is Array<Array<string>> where first row is column headers
  // E.g. [["ColA", "ColB"], ["a1", "b1"], ["a2", "b2"]]
  return REQUIRED_COLUMN_HEADERS.filter(
    (name) => !actualHeaders.includes(name)
  );
};
