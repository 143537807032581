import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import Logger from '../modules/Logger.js';
import { Modal, Form, Button} from 'react-bootstrap';
import LineOfBusiness from './LineOfBusiness';
import Description from '../modules/Description';

export default class EditAddDescription extends Component {

  constructor(props) {
    super(props);
    this.state = {
      updateSucessful: true,
      saveInProgress: false,
      description: this.props.description,
    };
  }

  handleDescriptionChange = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      description: event.target.value,
    });
  }

  handleClose = () => {
    this.props.closeModal();
  }

  getTitle = () => {
    return this.props.title;
  }

  updateDescription = async (messageType, description) => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      const isUpdateSuccess = await Description
        .updateDescriptionMessage(
          messageType,
          description,
          idJwtToken,          
        );

      return isUpdateSuccess;
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  };

  handleSubmit = async (event) => {
    if (this.state.saveInProgress) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    this.setState({saveInProgress: true});

    const isUpdateSuccess = await this.
      updateDescription(
        this.props.messageType,
        this.state.description,
      );

    if (isUpdateSuccess) {
      this.props.updateLocalMessage(this.state.description);
      this.props.closeModal();
    } else {
      this.setState({
        updateSucessful: false,
        saveInProgress: false,
      });
    }
  }

  getSaveButton = () => {
    return this.state.saveInProgress
      ? <Button
        disabled
        type="submit"
        className="submitCloseButtons">
        Saving...
      </Button>
      : <Button
        type="submit"
        className="submitCloseButtons">
        Save
      </Button>;
  }

  getCloseButton = () => {
    return this.state.saveInProgress
      ? <Button
        disabled
        variant="outline-secondary"
        onClick={this.handleClose}
        className="submitCloseButtons">
        Close
      </Button>
      : <Button
        variant="outline-secondary"
        onClick={this.handleClose}
        className="submitCloseButtons">
        Close
      </Button>;
  }

  render() {
    const modalTitle = this.getTitle();
    const saveButton = this.getSaveButton();
    const closeButton = this.getCloseButton();

    return (
      <div>
        <Modal show={this.props.modalStatus} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form
              noValidate
              onSubmit={this.handleSubmit}
              className="conatiner">

              <div className="formSection noBorder">
                <LineOfBusiness
                  linesOfBusiness={[this.props.currentLineOfBusiness]}
                  currentLineOfBusiness={this.props.currentLineOfBusiness}
                  readOnly={true}
                  className="col-12"
                />

                <Form.Group controlId="Description">
                  <Form.Label
                    className="formLabel">
                    Description
                  </Form.Label>

                  <Form.Control
                    as="textarea"
                    className="descriptionMessage"
                    required
                    type="text"
                    defaultValue={this.state.description}
                    onChange={this.handleDescriptionChange}
                    name="Description"
                  />
                </Form.Group>

              </div>
              {saveButton}
              {closeButton}

              <div className={
                this.state.updateSucessful
                  ? 'removeContent'
                  : 'displayContent validateForm'}>
                Updating Planned message was not successful. Please try again.
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
