import React, { Component } from 'react';
import {Container, Table, OverlayTrigger,
  Popover} from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logger from '../modules/Logger.js';
import Description from '../modules/Description';
import DescriptionEditModal from './DescriptionEditModal.js';
import EditableHoursTable from './EditableHoursTable';

import moment from 'moment';

export default class hoursOfBusiness extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      descriptionModal: false,
      description: null,
    };
  }

  componentDidMount() {
    this.loadDescription();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== undefined
      && prevProps.currentLineOfBusiness !== this.props.currentLineOfBusiness
    ) {
      this.loadDescription();
    }
  }

  async loadDescription() {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();

    if (idJwtToken) {
      const description =
        await Description.getDescriptionMessage(
          'businessHoursMessage',
          idJwtToken,
        );

      this.setState({
        description,
      });
    } else {
      Logger.verbose(JSON.stringify(`JWT TOKEN is null: ${idJwtToken}`));
    }
  }

  openModal = (day, index) => {
    this.setState({showModal: true, editHoursIndex: index, editHoursDay: day});
  };

  closeModal = (notReset) => {
    if (!notReset) {
      this.props.resetBusinessHours();
    }
    this.setState({showModal: false, editHoursIndex: null, editHoursDay: null});
  }

  openDescriptionEditModal = () => {
    this.setState({
      descriptionModal: true,
    });
  }

  closeDescriptionModal = () => {
    this.setState({
      descriptionModal: false,
    });
  }

  updateLocalDescription = (newDescription) => {
    this.setState({
      description: newDescription,
    });

    return true;
  }

  buildTableValuesElements = (day, businessHours, index) => {
    let formattedTime = 'N/A';
    if (businessHours.isOpen24Hours) {
      formattedTime = 'Open 24 hours';
    } else if (businessHours.isClosed) {
      formattedTime = 'Closed';
    } else if (Array.isArray(businessHours.hours)
        && businessHours.hours.length > 0) {
      formattedTime = businessHours.hours
        .map(hours => {
          const start =
            moment(hours.startTime, 'h:mm a').format('h:mm a');
          const end =
            moment(hours.endTime, 'h:mm a').format('h:mm a');
          return `${start} - ${end}`;
        })
        .join(', ');
      formattedTime += ' PST';
    }

    return <tr key={index}>
      <td className="tableCell">{day}</td>
      <td className="tableCell">{formattedTime}</td>
      <td className="tableCell">
        <FontAwesomeIcon
          className="editIcon ml-0"
          icon="pen"
          onClick={() => this.openModal(day, index)}
        />
      </td>
    </tr>;
  };

  buildStartAndEndTime = (businessHours) => {
    const businessHoursRows = [];

    businessHoursRows.push(
      this.buildTableValuesElements('Sunday', businessHours[0], 0));
    businessHoursRows.push(
      this.buildTableValuesElements('Monday', businessHours[1], 1));
    businessHoursRows.push(
      this.buildTableValuesElements('Tuesday', businessHours[2], 2));
    businessHoursRows.push(
      this.buildTableValuesElements('Wednesday', businessHours[3], 3));
    businessHoursRows.push(
      this.buildTableValuesElements('Thursday', businessHours[4], 4));
    businessHoursRows.push(
      this.buildTableValuesElements('Friday', businessHours[5], 5));
    businessHoursRows.push(
      this.buildTableValuesElements('Saturday', businessHours[6], 6));

    return businessHoursRows;
  };

  editHoursTable = (editHoursIndex, editHoursDay) => {
    return <EditableHoursTable
      hoursOfBusiness={this.props.hoursOfBusiness}
      editableHours={this.props.hoursOfBusiness[editHoursIndex]}
      editableDay={editHoursDay}
      modalStatus={this.state.showModal}
      closeModal={this.closeModal}
      setBusinessHours={(newHours) => 
        this.updateBusinessHours(editHoursIndex, newHours)}
      linesOfBusiness={this.props.linesOfBusiness}
      currentLineOfBusiness={this.props.currentLineOfBusiness}
      changeLineOfBusiness={this.props.changeLineOfBusiness}
    />;
  }

  updateBusinessHours = async (hoursIndex, newHours) => {
    const updatedHours = [...this.props.hoursOfBusiness];
    updatedHours[hoursIndex] = newHours;

    return this.props.setBusinessHours(updatedHours);
  }

  render() {
    const tableValues = this.buildStartAndEndTime(this.props.hoursOfBusiness);
    const editHoursTable = this.state.showModal
      ?  this.editHoursTable(this.state.editHoursIndex, this.state.editHoursDay)
      : null;
    const descriptionModal = this.state.descriptionModal
      ? <DescriptionEditModal
        modalStatus={this.state.descriptionModal}
        closeModal={this.closeDescriptionModal}
        currentLineOfBusiness={this.props.currentLineOfBusiness}
        updateLocalMessage={this.updateLocalDescription}
        description={this.state.description}
        title={'Hours Of Operation Description'}
        messageType={'businessHoursMessage'}
      />
      : null;
    return (
      <Container className="containerBody">
        <div className="businessHoursTitle">
          Business Hours (PST)

          <OverlayTrigger trigger="click" placement="right" overlay={
            <Popover
              id="popover-basic" title="Hours Of Operation Description">
              {this.state.description}
            </Popover>
          }>
            <span>
              <FontAwesomeIcon
                className="editIcon action-icon mr-2"
                icon="comment"
                variant="success"
              />
            </span>
          </OverlayTrigger>
          <FontAwesomeIcon
            className="editIcon action-icon mr-2"
            icon="pen"
            onClick={() => this.openDescriptionEditModal()}
          />
        </div>

        <div className="readOnlyTableContainer">
          <Table striped bordered hover responsive size="sm">
            <thead className="tableHead">
              <tr>
                <th style={{ width: '15%' }}>Day</th>
                <th style={{ width: '80%' }}>Hours</th>
                <th style={{ width: '5%' }}></th>
              </tr>
            </thead>
            <tbody>
              {tableValues}
            </tbody>
          </Table>
        </div>

        {editHoursTable}
        {descriptionModal}
      </Container>
    );
  }
}
