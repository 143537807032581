import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Rule from '@starbucks/pattern-library/lib/components/rule';
import Animator from '@starbucks/pattern-library/lib/components/animator';

export default class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getCloseButton = () => {
    return (
      <Button
        variant="outline-secondary"
        onClick={this.handleClose}
        className="mb-3 submitCloseButtons"
        name="Close Button" >
        OK
      </Button>
    );
  }

  getTitle = () => {
    return (
      <Animator animation="fade">
        <div className="row">
          <div className="col">
            <h4>Success
              <Rule className="pt3 pt3" />
            </h4>
            <p>Your changes will be effective immediately, but may take up to 10 minutes to appear here.</p>
          </div>
        </div>
      </Animator>
    );
  }

  handleClose = () => {
    this.props.closeModal();
  }

  render() {
    return (
      <Modal show={this.props.modalStatus} onHide={this.handleClose}>
        <Modal.Header closeButton>
          {this.getTitle()}
        </Modal.Header>
        <Modal.Body>
          <Form>
            {this.getCloseButton()}
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}
