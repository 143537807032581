import React, { Component } from 'react';
import { Container, Button, ButtonToolbar } from 'react-bootstrap';

import LineOfBusiness from '../components/LineOfBusiness';
import AgentsTabsOfContent from '../components/AgentsTabsOfContent';
import Status from '../components/Status';
import ConfirmationModal from '../components/ConfirmationModal';
import Permissions from '../modules/Permissions.js';
import { Auth } from 'aws-amplify';
import './AgentContainer.css';

export default class AgentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationModal: false,
      permissions: [],
    };
  }

  componentDidMount() {
    this.getPermissions();
  }

  getPermissions = async () => {
    const session = await Auth.currentSession();
    const idJwtToken = session.getIdToken().getJwtToken();
    try {
      if (idJwtToken) {
        const permissions = await Permissions.getPermissions(idJwtToken);
        this.setState({
          permissions,
        });
      }
    } catch (error) {
      console.log('An error has occurred in the permissions API call', error);
      this.props.closeModal();
    }
  }

  closeModal = () => {
    this.setState({
      showConfirmationModal: false,
    });
  }

  closeModalWithConfirmation = () => {
    this.setState({
      showConfirmationModal: true,
    });
  }

  render() {
    const confirmationModal = this.state.showConfirmationModal
      ? <ConfirmationModal
        closeModal={this.closeModal}
        closeModalWithConfirmation={this.closeModalWithConfirmation}
        modalStatus={this.state.showConfirmationModal}
      />
      : null;

    return (
      <Container className="agentContainer">
        <div className="titleAndStatusContainer">
          <LineOfBusiness
            linesOfBusiness={this.props.linesOfBusiness}
            currentLineOfBusiness={this.props.currentLineOfBusiness}
            changeLineOfBusiness={this.props.changeLineOfBusiness}
            readOnly={false}
          />
          <Status
            status={this.props.status}
          />
        </div>
        {confirmationModal}
        <AgentsTabsOfContent
          linesOfBusiness={this.props.linesOfBusiness}
          currentLineOfBusiness={this.props.currentLineOfBusiness}
          changeLineOfBusiness={this.props.changeLineOfBusiness}
          userName={this.props.userName}
          readOnly={false}
          permissions={this.state.permissions}
        />
      </Container>
    );
  }
}
