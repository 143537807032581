import React from 'react'

const sizePerPageOptionRenderer = ({
  text,
  page,
  onSizePerPageChange
}) => (
  <li
    key={text}
    role="presentation"
    className="dropdown-item"
  >
    <a
      href={window.location.href}
      tabIndex="-1"
      role="menuitem"
      data-page={page}
      onMouseDown={(e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
      style={{ display: 'block', textAlign: 'center', color: '#00653e' }}
    >
      {text}
    </a>
  </li>
);

export const sortByColumn = (data, sortField, sortOrder) => {
  if (sortField && sortOrder) {
    if (sortOrder === 'asc') {
      data.sort((a, b) => (a[sortField] > b[sortField]) ? 1 : -1);
    } else {
      data.sort((a, b) => (b[sortField] > a[sortField]) ? 1 : -1);
    }
  }
  return data;
}

export const filterByValue = (dataArray, searchText, filterKeys) => {
  return dataArray.filter(o =>
     filterKeys.some(k => o[k].toString().toLowerCase().includes(searchText.toLowerCase())));
  }

export const resetPaginationOptions = () => {
  let paginationOptions = pagination;
  paginationOptions.page = 1;
  paginationOptions.totalSize = 0;
  paginationOptions.sizePerPage = 25;
}

export const pagination = {
  page: 1,
  lastPageText: '>>',
  firstPageText: '<<',
  nextPageText: '>',
  prePageText: '<',
  showTotal: true,
  alwaysShowAllBtns: true,
  totalSize: 0,
  sizePerPage: 25,
  sizePerPageList: [{
    text: '10', value: 10,
  }, {
    text: '25', value: 25,
  }, {
    text: '50', value: 50,
  }],
  sizePerPageOptionRenderer
};

export const getDataForCurrentPage = (data, pagination) => {
  const currentPage = pagination.page;
  const to = (currentPage * pagination.sizePerPage);
  const from = (to - pagination.sizePerPage);
  return data.slice(from, to);
}