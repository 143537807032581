import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import 'jquery';
import 'bootstrap';
import 'popper.js';
import 'antd/dist/antd.css';
import 'rc-time-picker/assets/index.css';
import 'react-widgets/dist/css/react-widgets.css';
import 'react-datetime/css/react-datetime.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
