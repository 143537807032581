import JwtProvider from './JwtProvider';
const AUTH_HEADER_NAME = 'Authorization';

/**
 * Handles all authorization related tasks
 * @author Adam Rau
 */
export default class Authorization {

  /**
   * Get the fully qualified authorization header
   * @returns the authorization header to be used by the API call
   */
  static getAuthHeader = async () => {
    const token = await JwtProvider.getToken();

    const authHeader = {
      headers: {
        [AUTH_HEADER_NAME]: token
      },
    };

    return authHeader;
  };

}
