import Settings from './Settings.js';
import Logger from './Logger.js';
import apigClientFactory from 'aws-api-gateway-client';

export default class BusinessHours  {
  static getBusinessHours = async (LOB, idJwtToken) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/${LOB}/business-hours`;
    const method = 'GET';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {};

    const businessHours = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    Logger.verbose(businessHours);
    return businessHours.data.businessHours;
  };

  static updateBusinessHours = async (LOB, updatedHours, idJwtToken) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };
    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {
    };
    const pathTemplate = `/lob/${LOB}/business-hours`;
    const method = 'POST';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {
      businessHours: updatedHours,
    };

    const updateBusinessHours = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    Logger.verbose(JSON.stringify(
      `Line of Business updated: ${updateBusinessHours.data.LineOfBusiness}`));
    return updateBusinessHours.data.LineOfBusiness;
  };
}
