import React, { Component } from 'react';

export default class Status extends Component {
  buildStatusElements = (status) => {
    if (status === null) {
      return <div className="resourceManagerStatus">
        <div className="lineOfBusinessTitle">
          Status
        </div>
      </div>;
    }

    const listOfKeys = ['businessOpen', 'category',
      'messageType', 'message_en', 'message_fr', 'starbuckOpen', 'success'];

    const statusKeys = Object.keys(status);

    const locationKeys = statusKeys.filter(x => !listOfKeys.includes(x));

    const locations = locationKeys.map((x, index) => {
      return <div key={index}>
        {`${x[0].toUpperCase() + x.slice(1)} Hours Exception: ${status[x]
          ? 'None'
          : 'Closed'}`}
      </div>;
    });

    if (status.starbuckOpen) {
      return <div className="resourceManagerStatus">
        <div className="lineOfBusinessTitle">
          Status
        </div>
        <div>
          {`Business Hours: ${status.businessOpen
            ? 'Open'
            : 'Closed'}`}
        </div>
        <div>
          {`Situational Hours Exception: None`}
        </div>
        <div>
          {locations}
        </div>
      </div>;
    }

    const contactCenterStatus = `Closed - ${status.category.toUpperCase()}`;

    return <div className="resourceManagerStatus">
      <div className="lineOfBusinessTitle">
        Status
      </div>
      <div>
        {`Business Hours: ${status.businessOpen
          ? 'Open'
          : 'Closed'}`}
      </div>
      <div>
        {`Situational Hours Exception: ${contactCenterStatus}`}
      </div>
      <div>
        {locations}
      </div>
    </div>;
  }

  render() {
    const resourceManagerStatus = this.buildStatusElements(
      this.props.status,
    );
    return (
      <div className="resourceManagerStatusContainer">
        {resourceManagerStatus}
      </div>
    );
  }
}
