import Settings from './Settings.js';
import Logger from './Logger.js';
import apigClientFactory from 'aws-api-gateway-client';

export default class Permissions {
  static getPermissions = async (idJwtToken) => {
    const config = {
      invokeUrl: Settings.starbucksAPIURL,
    };

    const apigClient = apigClientFactory.newClient(config);
    const pathParams = {};
    const pathTemplate = `/user-permission`;
    const method = 'GET';
    const additionalParams = {
      headers: {
        Authorization: idJwtToken,
      },
    };
    const body = {};

    const lob = await apigClient.invokeApi(
      pathParams,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    Logger.verbose(JSON.stringify(lob));

    const permissions = lob.data.permissions;

    if (!permissions.isGlobalAdmin) {
      let isStarbucksSupervisor = false;
      const lobKeys = Object.keys(permissions.contactCenterPermissions);
      for (let i = 0; i < lobKeys.length; i++) {
        if (permissions.contactCenterPermissions[lobKeys[i]].includes(0)) {
          isStarbucksSupervisor = true;
          break;
        }
      }
      permissions.isStarbucksSupervisor = isStarbucksSupervisor;
    }

    if (!permissions.isGlobalAdmin && !permissions.isStarbucksSupervisor) {
      permissions.isLocationSupervisor = Object.keys(
        permissions.contactCenterPermissions,
      ).length > 0;
    }

    permissions.resourceManagerPermissions = {};

    permissions.resourceManagerPermissions.canSeeContactCenterPage =
      permissions.isGlobalAdmin || permissions.isStarbucksSupervisor;

    const hasPermissions = permissions.isGlobalAdmin
      || permissions.isStarbucksSupervisor
      || permissions.isLocationSupervisor;
    permissions.resourceManagerPermissions.canSeeLocationPage = hasPermissions;
    permissions.resourceManagerPermissions.canSeeAgentPage = hasPermissions;
    permissions.resourceManagerPermissions.canSeeRealTimePage = hasPermissions;

    permissions.resourceManagerPermissions.canSeeLexPage =
      permissions.isGlobalAdmin;

    permissions.resourceManagerPermissions.canSeeGlobalAdminPage =
      permissions.isGlobalAdmin;

    return permissions;
  }
}