import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import LineOfBusiness from '../components/LineOfBusiness';
import Status from '../components/Status';
import RealTimeTabsOfContent from '../components/RealTimeTabsOfContent';

export default class RealTimeContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <Container>
          <div className="titleAndStatusContainer">
            <LineOfBusiness
              linesOfBusiness={this.props.linesOfBusiness}
              currentLineOfBusiness={this.props.currentLineOfBusiness}
              changeLineOfBusiness={this.props.changeLineOfBusiness}
              readOnly={false}
            />
            <Status
              status={this.props.status}
            />
          </div>
          <RealTimeTabsOfContent
              linesOfBusiness={this.props.linesOfBusiness}
              currentLineOfBusiness={this.props.currentLineOfBusiness}
              changeLineOfBusiness={this.props.changeLineOfBusiness}
              readOnly={false}
              permissions={this.props.permissions}
          />
        </Container>
      </div>
    );
  }
}